import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {MapContainer, TileLayer, Polygon} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {statesData} from '../../mock/data';

import Leftside from '../../common/leftside/Leftside';
// import Rightside from '../../common/rightside/Rightside';



const center = [40.34391822540472, 0.1039050285594445]

function Feed(){
    const [users, setUsers] = useState(null);
    const [posts, setPosts] = useState(null);
    useEffect(() =>{
        fetch('/api/users')
        .then(res => res.json())
        .then(json => setUsers(json.users))
        .catch(err => console.log(err))
    },[])

    useEffect(() =>{
        fetch('/api/posts')
        .then(res => res.json())
        .then(json => setPosts(json.posts))
        .catch(err => console.log(err))
    },[])


    return(
        <>

            <div className="main-content">
                <ul className="top-osahan-nav-tab nav nav-pills justify-content-center nav-justified mb-4 shadow-sm rounded-2 overflow-hidden bg-white sticky-sidebar2" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="p-2 nav-link text-muted active" id="pills-feed-tab" data-bs-toggle="pill" data-bs-target="#pills-feed" type="button" role="tab" aria-controls="pills-feed" aria-selected="true">By Area</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="p-2 nav-link text-muted" id="pills-people-tab" data-bs-toggle="pill" data-bs-target="#pills-people" type="button" role="tab" aria-controls="pills-people" aria-selected="false">By City</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="p-2 nav-link text-muted" id="pills-trending-tab" data-bs-toggle="pill" data-bs-target="#pills-trending" type="button" role="tab" aria-controls="pills-trending" aria-selected="false">
                            By State
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="p-2 nav-link text-muted" id="pills-trending-tab" data-bs-toggle="pill" data-bs-target="#pills-trending" type="button" role="tab" aria-controls="pills-trending" aria-selected="false">
                            By Country
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="p-2 nav-link text-muted" id="pills-trending-tab" data-bs-toggle="pill" data-bs-target="#pills-trending" type="button" role="tab" aria-controls="pills-trending" aria-selected="false">
                            By Worldwide
                        </button>
                    </li>
                </ul>




                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-feed" role="tabpanel" aria-labelledby="pills-feed-tab">
                        <div className="input-group shadow-sm rounded-2 overflow-hidden py-2 bg-white" data-bs-toggle="modal" data-bs-target="#postModal">
                            <span className="input-group-text material-icons border-0 bg-white text-secondary">account_circle</span>
                            <input type="text" className="form-control border-0 fw-light ps-1" placeholder="What's the issue." />
                            <Link to="#" className="text-decoration-none input-group-text bg-white border-0 material-icons text-secondary">add_circle</Link>
                        </div>

                            {users?.length > 0 ? (
                                <div className="pt-4 feeds mb-2">
                                    {users.map(({id, name, username}) =>
                                        <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm">
                                            <div className="d-flex">
                                                <div className="d-flex align-items-start w-100">
                                                    <div className="w-100">
                                                        <div key={id}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={require('../../assets/img/rmate2.jpg')} className="me-2 img-fluid rounded-circle user-img" alt="profile-img" />
                                                                    <Link to="/profile" className="text-decoration-none">
                                                                        <h6 className="fw-bold mb-0 text-body">{name}</h6>
                                                                        <div>
                                                                            <span className="material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                                            <small className="text-muted ms-2">{username}</small>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                                <div className="d-flex align-items-center small">
                                                                    <div className="dropdown">
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            more_vert
                                                                        </Link>
                                                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                            <li>
                                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Report</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Hide</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Share via another apps</Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {posts.map(({id, que, options, date, location, affectedUser, commentCount, share, comments}) =>
                                                            <div key={id}>
                                                                <div className="my-2 card p-2">
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <p className="text-dark">{que}</p>
                                                                        <div className='d-flex'>
                                                                            <p className="text-muted mb-0 small me-1">{date}</p>
                                                                            <p className='btn btn-secondary btn-dm btn-rounded py-0'>{location}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='card p-2 mb-2'>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="btn-group d-flex pollOptionLabel" role="group" aria-label="Basic radio toggle button group">
                                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"/>
                                                                                    <label className="btn btn-outline-secondary" htmlFor="btnradio1">{options.option1}</label>

                                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"/>
                                                                                    <label className="btn btn-outline-secondary" htmlFor="btnradio2">{options.option2}</label>

                                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"/>
                                                                                    <label className="btn btn-outline-secondary" htmlFor="btnradio3">{options.option3}</label>

                                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off"/>
                                                                                    <label className="btn btn-outline-secondary" htmlFor="btnradio4">{options.option4}</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                                                                <button className='taggedNeta btn btn-outline-secondary btn-sm btn-rounded followers d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#taggedModal">
                                                                                    <p className="m-0 text-decoration-none border-0 material-icons">add_circle</p>
                                                                                    <p className='m-0'>Tagged Neta</p>
                                                                                    <div className="d-flex me-2">
                                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                                        <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                                        <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                                    </div>
                                                                                    <p className='m-0'>+150</p>
                                                                                </button>

                                                                                <button className='btn btn-secondary btn-sm taggedNeta btn-rounded d-flex align-items-center'  data-bs-toggle="modal" data-bs-target="#referenceModal"><p className="m-0 text-decoration-none border-0 material-icons">add_circle</p> Reference Information/Material</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='chartSection rounded-2'>
                                                                                <div className="d-flex mb-2">
                                                                                    <select className='form-select me-2'>
                                                                                        <option defaultValue="">Chart</option>
                                                                                        <option id='bar' value="bar">Bar Chart</option>
                                                                                        <option id='pie' value="pie">Pie Chart</option>
                                                                                        <option value="geographic">Geographic Chart</option>
                                                                                    </select>

                                                                                    <select className='form-select me-2'>
                                                                                        <option defaultValue="">Age Group</option>
                                                                                        <option value="1">18-25</option>
                                                                                        <option value="2">26-35</option>
                                                                                        <option value="3">36-50</option>
                                                                                        <option value="3">51-65</option>
                                                                                    </select>

                                                                                    <select className='form-select me-2'>
                                                                                        <option defaultValue="">Time</option>
                                                                                        <option value="1">1 day</option>
                                                                                        <option value="2">Week</option>
                                                                                        <option value="3">15 Days</option>
                                                                                        <option value="3">Month</option>
                                                                                        <option value="4">3 Months</option>
                                                                                        <option value="6">6 Months</option>
                                                                                    </select>

                                                                                    <select className='form-select'>
                                                                                        <option defaultValue="">Location</option>
                                                                                        <option value="area">By Area</option>
                                                                                        <option value="city">By City</option>
                                                                                        <option value="state">By State</option>
                                                                                        <option value="country">By Country</option>
                                                                                        <option value="world">By World</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    {/* <Map view={{center:[0,0], zoom:2}}>
                                                                                        <Layers>
                                                                                            <layer.Tile></layer.Tile>
                                                                                        </Layers>
                                                                                    </Map> */}

                                                                                    <MapContainer className='mapContainer' center={center} zoom={2} style={{width: '100vw', height: '400px'}}>
                                                                                        <TileLayer
                                                                                            url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=ouo6jpbbAqUPb21n7V5F"
                                                                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                                                                        >
                                                                                        </TileLayer>
                                                                                        {
                                                                                            statesData.features.map((state) => {
                                                                                                const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);

                                                                                                return (<Polygon
                                                                                                    pathOptions ={{
                                                                                                        fillColor: '#888',
                                                                                                        fillOpacity: 0.7,
                                                                                                        weight: 2,
                                                                                                        opacity:1,
                                                                                                        dashArray: 3,
                                                                                                        color: 'white'
                                                                                                    }}
                                                                                                    positions = {coordinates}
                                                                                                    eventHandlers={{
                                                                                                        mouseover: (e) => {
                                                                                                            const layer = e.target;
                                                                                                            layer.setStyle({
                                                                                                                fillOpacity: 0.7,
                                                                                                                weight: 2,
                                                                                                                dashArray: "",
                                                                                                                color: "#333",
                                                                                                                fillColor: '#555'
                                                                                                            })
                                                                                                        },
                                                                                                        mouseout: (e) => {
                                                                                                            const layer = e.target;
                                                                                                            layer.setStyle({
                                                                                                                fillOpacity: 0.7,
                                                                                                                weight: 2,
                                                                                                                dashArray: "3",
                                                                                                                color: "white",
                                                                                                                fillColor: '#888'
                                                                                                            })
                                                                                                        },
                                                                                                        click: (e) => {

                                                                                                        }
                                                                                                    }}
                                                                                                />)
                                                                                            })
                                                                                        }

                                                                                        <div className='ageGroup'>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color1'>40%</span>
                                                                                                <span className='option'>18-25</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color2'>30%</span>
                                                                                                <span className='option'>26-35</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color3'>20%</span>
                                                                                                <span className='option'>36-50</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color4'>10%</span>
                                                                                                <span className='option'>50-70</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='showOptions'>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color1'>40%</span>
                                                                                                <span className='option'>Yes</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color2'>30%</span>
                                                                                                <span className='option'>No</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color3'>20%</span>
                                                                                                <span className='option'>Maybe</span>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <span className='sqaure color4'>10%</span>
                                                                                                <span className='option'>None of above</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='genderRatio'>
                                                                                            <div className='d-flex align-items-center mb-2'>
                                                                                                <div className='me-2'><i class="fa-solid fa-person fa-3x"></i></div>
                                                                                                <div className='d-flex flex-column justify-content-center text-center'>
                                                                                                    <span className='option'>60%</span>
                                                                                                    <span className='option'>(16k)</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                                <div className='me-2'><i class="fa-solid fa-person-dress fa-3x"></i></div>
                                                                                                <div className='d-flex flex-column justify-content-center text-center'>
                                                                                                    <span className='option'>40%</span>
                                                                                                    <span className='option'>(9k)</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </MapContainer>

                                                                                    {/* <Chart chartType="GeoChart" width="100%" height="260px" data={geoData} /> */}
                                                                                    {/* <Bar options={options} data={data} /> */}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                                                                    <div>
                                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                                            <span className="material-icons md-20 me-2">thumb_up_off_alt</span><span>Affected User({affectedUser})</span>
                                                                        </Link>
                                                                    </div>
                                                                    <div>
                                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                                            <span className="material-icons md-20 me-2">chat_bubble_outline</span><span>Commects({commentCount})</span>
                                                                        </Link>
                                                                    </div>
                                                                    <div>
                                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light"><span className="material-icons md-18 me-2">share</span><span>Share({share})</span></Link>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center mb-3" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                    <span className="material-icons text-secondary border-0 text-primary pe-2 md-36">account_circle</span>
                                                                    <input type="text" className="form-control form-control-sm rounded-2 fw-light" placeholder="Write Your comment" />
                                                                </div>
                                                                <div className="comments">
                                                                    <div className="d-flex mb-2">
                                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                            <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                                        </Link>
                                                                        <div className="ms-2 small">
                                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                                    <p className="fw-500 mb-0">{comments[0].name}</p>
                                                                                    <span className="text-muted">{comments[0].comment}</span>
                                                                                </div>
                                                                            </Link>
                                                                            <div className="d-flex align-items-center ms-2">
                                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <span className="small text-muted">1h</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mb-2">
                                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                                        </Link>
                                                                        <div className="ms-2 small">
                                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                                    <p className="fw-500 mb-0">{comments[1].name}</p>
                                                                                    <span className="text-muted">
                                                                                        {comments[1].comment}
                                                                                    </span>
                                                                                </div>
                                                                            </Link>
                                                                            <div className="d-flex align-items-center ms-2">
                                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <span className="small text-muted">20min</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mb-2">
                                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                            <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                                        </Link>
                                                                        <div className="ms-2 small">
                                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                                    <p className="fw-500 mb-0">{comments[0].name}</p>
                                                                                    <span className="text-muted">{comments[0].comment}</span>
                                                                                </div>
                                                                            </Link>
                                                                            <div className="d-flex align-items-center ms-2">
                                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                                <span className="small text-muted">10min</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <p>No Post</p>
                            )}
                    </div>
                    <div className="tab-pane fade" id="pills-people" role="tabpanel" aria-labelledby="pills-people-tab">
                        <div className="input-group shadow-sm mb-3 rounded-2 overflow-hidden py-2 bg-white" data-bs-toggle="modal" data-bs-target="#postModal">
                            <span className="input-group-text material-icons border-0 bg-white text-primary">account_circle</span>
                            <input type="text" className="form-control border-0 fw-light ps-1" placeholder="What's the issue." />
                            <Link to="#" className="text-decoration-none input-group-text bg-white border-0 material-icons text-primary">add_circle</Link>
                        </div>
                        <div className="feeds">
                            <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm">
                                <div className="d-flex">
                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle user-img" alt="profile-img" />
                                    <div className="d-flex ms-3 align-items-start w-100">
                                        <div className="w-100">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Link to="/profile" className="text-decoration-none d-flex align-items-center">
                                                    <h6 className="fw-bold mb-0 text-body">Name</h6>
                                                    <span className="ms-2 material-icons bg-primary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                    <small className="text-muted ms-2">username</small>
                                                </Link>
                                                <div className="d-flex align-items-center small">
                                                    <p className="text-muted mb-0">19 Apr</p>
                                                    <div className="dropdown">
                                                        <Link
                                                            to="#"
                                                            className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            more_vert
                                                        </Link>
                                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Report</Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Hide</Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Share via another apps</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-2">
                                                <p className="text-dark">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam labore nam omnis ratione minus ad unde, facere quod repudiandae rerum quas consequuntur excepturi, porro autem in quibusdam atque? Quos, distinctio.</p>
                                                {/* <Link to="#" className="text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                    <img src={require('../../assets/img/post1.png')} className="img-fluid rounded mb-3" alt="post-img" />
                                                </Link> */}
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-2">thumb_up_off_alt</span><span>Affected User(30.4k)</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-2">chat_bubble_outline</span><span>Commects(4.0k)</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light"><span className="material-icons md-18 me-2">share</span><span>Share</span></Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                    <span className="material-icons bg-white border-0 text-primary pe-2 md-36">account_circle</span>
                                                    <input type="text" className="form-control form-control-sm rounded-2 fw-light" placeholder="Write Your comment" />
                                                </div>
                                                <div className="comments">
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User2</p>
                                                                    <span className="text-muted">Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolor.</span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">1h</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User3</p>
                                                                    <span className="text-muted">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">20min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User4</p>
                                                                    <span className="text-muted">With our vastly improved notifications system, users have more control.</span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">10min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-pane fade" id="pills-trending" role="tabpanel" aria-labelledby="pills-trending-tab">
                        <div className="input-group shadow-sm mb-3 rounded-2 overflow-hidden py-2 bg-white" data-bs-toggle="modal" data-bs-target="#postModal">
                            <span className="input-group-text material-icons border-0 bg-white text-primary">account_circle</span>
                            <input type="text" className="form-control border-0 fw-light ps-1" placeholder="What's the issue." />
                            <Link to="#" className="text-decoration-none input-group-text bg-white border-0 material-icons text-primary">add_circle</Link>
                        </div>

                        <div className="feeds">
                            <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm">
                                <div className="d-flex">
                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle user-img" alt="profile-img" />
                                    <div className="d-flex ms-3 align-items-start w-100">
                                        <div className="w-100">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Link to="/profile" className="text-decoration-none d-flex align-items-center">
                                                    <h6 className="fw-bold mb-0 text-body">Anonymous User1</h6>
                                                    <span className="ms-2 material-icons bg-primary p-0 md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                    <small className="text-muted ms-2">@anonymous</small>
                                                </Link>
                                                <div className="d-flex align-items-center small">
                                                    <p className="text-muted mb-0">19 Apr</p>
                                                    <div className="dropdown">
                                                        <Link
                                                            to="#"
                                                            className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            more_vert
                                                        </Link>
                                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Report</Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Hide</Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Share via another apps</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-2">
                                                <p className="text-dark">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum iure aut exercitationem magni ipsum asperiores eaque, tempore nam dolorem aliquid vel. Illum numquam fugiat non vero perspiciatis, aut incidunt provident!</p>
                                                {/* <Link to="#" className="text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                    <img src={require('../../assets/img/post1.png')} className="img-fluid rounded mb-3" alt="post-img" />
                                                </Link> */}
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-2">thumb_up_off_alt</span><span>Affected User(30.4k)</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-2">chat_bubble_outline</span><span>Commects(4.0k)</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light"><span className="material-icons md-18 me-2">share</span><span>Share</span></Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                    <span className="material-icons bg-white border-0 text-primary pe-2 md-36">account_circle</span>
                                                    <input type="text" className="form-control form-control-sm rounded-2 fw-light" placeholder="Write Your comment" />
                                                </div>
                                                <div className="comments">
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User2</p>
                                                                    <span className="text-muted">Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolor.</span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">1h</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User3</p>
                                                                    <span className="text-muted">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">20min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                            <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="#" className="text-dark text-decoration-none" data-bs-toggle="modal" data-bs-target="#commentModal">
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">Anonymous User4</p>
                                                                    <span className="text-muted">With our vastly improved notifications system, users have more control.</span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">10min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mb-0 mt-2">Loading</p>
            </div>

            <Leftside/>
            {/* <Rightside/> */}

        </>
    );
}

export default Feed;