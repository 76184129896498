import React from 'react';
import { Link } from 'react-router-dom';



class Rightside extends React.Component{
    render(){
        return(
            <>
                <aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 ps-0">
                    <div className="fix-sidebar">
                        <div className="side-trend lg-none">
                            <div className="sticky-sidebar2 mb-3">
                                <div className="bg-white rounded-2 overflow-hidden shadow-sm account-follow mb-4">
                                    <h6 className="fw-bold text-body p-2 mb-0 border-bottom">Follow popular Neta</h6>

                                    <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                        <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                        <div>
                                            <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                            <div className="text-muted fw-light">
                                                <p className="mb-1 small">@neta1</p>
                                                <span className="text-muted d-flex align-items-center small">Politician</span>
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck8" />
                                                <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                        <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                        <div>
                                            <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                            <div className="text-muted fw-light">
                                                <p className="mb-1 small">@neta2</p>
                                                <span className="text-muted d-flex align-items-center small">Politician</span>
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck9" />
                                                <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                        <div>
                                            <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                            <div className="text-muted fw-light">
                                                <p className="mb-1 small">@neta3</p>
                                                <span className="text-muted d-flex align-items-center small">Politician</span>
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck10" />
                                                <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="bg-white rounded-2 overflow-hidden shadow-sm mb-4">
                                    <h6 className="fw-bold text-body p-2 mb-0 border-bottom">What's happening</h6>

                                    <Link to="/trending" className="p-2 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                        <div>
                                            <div className="text-muted fw-light d-flex align-items-center"><small>COVID-19</small><span className="mx-1 material-icons md-3">circle</span><small>Live</small></div>
                                            <p className="fw-bold mb-0 pe-3">India records 2,541 fresh COVID-19 cases, 30 deaths</p>
                                        </div>
                                    </Link>

                                    <Link to="/trending" className="p-2 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                        <div>
                                            <div className="text-muted fw-light d-flex align-items-center"></div>
                                            <p className="fw-bold mb-0 pe-3">CEO of Twitter</p>
                                            <small className="text-muted">CEO of Twitter</small><br />
                                            <small className="text-muted d-flex align-items-center"><span className="material-icons me-1 small">open_in_new</span>Promoted by Selectrics World</small>
                                        </div>
                                    </Link>

                                    <div className="p-2 border-bottom d-flex">
                                        <div>
                                            <div className="text-muted fw-light d-flex align-items-center">
                                                <small>Trending in India</small>
                                            </div>
                                            <p className="fw-bold mb-0 pe-3 text-dark">#ME11Lite</p>
                                            <small className="text-muted">Buy now with exclusive offers</small><br />
                                        </div>
                                        <div className="dropdown ms-auto">
                                            <Link to="#" className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1" id="dropdownMenuButton5" data-bs-toggle="dropdown" aria-expanded="false">more_vert</Link>
                                            <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton5">
                                                <li>
                                                    <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>Not interested in this</Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>This trend is harmful or spammy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <Link to="/explore" className="text-decoration-none">
                                        <div className="p-2">Show More</div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </aside>
            </>
        );
    }
}

export default Rightside;