import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ChartSec from './Chartsec';
import Leftside from '../../common/leftside/Leftside';
// import Rightside from '../../common/rightside/Rightside';
// import postData from '../../mock/postData.json';
// import userDetail from '../../mock/userDetail.json';
import allData from '../../mock/allData.json';


function Post(){
    const [data, setData] = useState({ yes: 30, no: 20, maybe: 40,none: 10 });
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        const per = ((data.yes + data.no + data.maybe + data.none) / 400) * 100;
        setPercentage(per);
    }, [data]);
    const count = (e) => {
        console.log(e.target.value);
        setData({
            ...data,
            [e.target.name]: parseInt(e.target.value)
        });
    };
    console.log(allData[0].user[0].post[0].comments.length)


    return(
        <>
            <div className="main-content">
                {
                    allData && allData.map( detail => {
                        return(
                            <div className="feeds mb-2">
                                <div className="bg-white p-2 feed-item rounded-2 mb-3 shadow-sm">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-start w-100">
                                            <div className="w-100">
                                                <div key={detail.user[0].id}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={require('../../assets/img/rmate2.jpg')} className="me-2 img-fluid rounded-circle user-img" alt="profile-img" />
                                                            <Link to="/profile" className="text-decoration-none">
                                                                <h6 className="fw-bold mb-0 text-body">{detail.user[0].name}</h6>
                                                                <div>
                                                                    <span className="material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                                    <small className="text-muted ms-2">{detail.user[0].username}</small>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        <div className="d-flex align-items-center small">
                                                            <div className="dropdown">
                                                                <Link
                                                                    to="#"
                                                                    className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                                    id="dropdownMenuButton1"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    more_vert
                                                                </Link>
                                                                <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                    <li>
                                                                        <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Report</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Hide</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Share via another apps</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-2 card p-2">
                                                    <p className="text-dark mb-1">{detail.user[0].post[0].poll.question}</p>
                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                        <Link to="/pfi32bid348a92harn574ad15ufa8abb" className="text-muted mb-0 small me-1">{detail.user[0].post[0].time}</Link>
                                                        <small className='btn btn-secondary btn-dm btn-rounded py-0 m-0'><i className="fa-solid fa-location-dot"></i> {detail.user[0].post[0].location.name}</small>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center mt-1 mb-3'>
                                                        <button className='taggedNeta btn btn-outline-secondary btn-sm btn-rounded followers d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#taggedModal">
                                                            <p className="m-0 text-decoration-none border-0 material-icons">add_circle</p>
                                                            <p className='m-0'>Tagged Neta</p>
                                                            <div className="d-flex me-2">
                                                                <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                            </div>
                                                            <p className='m-0'>+150</p>
                                                        </button>
                                                        <button className='btn btn-outline-secondary btn-sm taggedNeta btn-rounded d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#detailInfo"><p className="m-0 text-decoration-none border-0 material-icons">add_circle</p> Reference Information/Material</button>
                                                    </div>
                                                    <div className='card card2 p-2 mb-2'>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="btn-group d-block pollOptionLabel" role="group">
                                                                    <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#allOption">All Users
                                                                        <div className='d-flex align-items-center justify-content-end'>
                                                                            <button className='btn btn-secondary2 btn-sm me-2'>21,554 Votes</button>
                                                                            <button className='btn btn-secondary2 btn-sm me-2'>100%</button>
                                                                            <button className='btn btn-secondary2 btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                        </div>
                                                                    </label>

                                                                    <div className="collapse" id="allOption">
                                                                        <hr className='hr-bold' />
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"/>
                                                                        <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio1">{detail.user[0].post[0].poll.options[0].option}
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-secondary2 btn-sm me-2'>9554 Votes</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm me-2'>46%</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                            </div>
                                                                        </label>

                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"/>
                                                                        <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio2">{detail.user[0].post[0].poll.options[1].option}
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-secondary2 btn-sm me-2'>8845 Votes</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm me-2'>39%</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                            </div>
                                                                        </label>

                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"/>
                                                                        <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio3">{detail.user[0].post[0].poll.options[2].option}
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-secondary2 btn-sm me-2'>2412 Votes</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm me-2'>10%</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                            </div>
                                                                        </label>

                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off"/>
                                                                        <label className="btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio4">{detail.user[0].post[0].poll.options[3].option}
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-secondary2 btn-sm me-2'>743 Votes</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm me-2'>5%</button>
                                                                                <button className='btn btn-outline-secondary text-dark btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 collapse" id="chartCollapse">
                                                            <ChartSec></ChartSec>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-1">thumb_up_off_alt</span><span>Affected User (<button type='button' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">{JSON.parse(JSON.stringify(detail.user[0].post[0].affected_people)).length}</button>)</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light">
                                                            <span className="material-icons md-20 me-1">chat_bubble_outline</span><span>Commects ({JSON.parse(JSON.stringify(detail.user[0].post[0].comments)).length})</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="#" className="text-muted text-decoration-none d-flex align-items-center fw-light"><span className="material-icons md-18 me-1">share</span><span>Share (<button type='button' data-bs-toggle="modal" data-bs-target="#shareUser">{JSON.parse(JSON.stringify(detail.user[0].post[0].shares)).length}</button>)</span></Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-3" >
                                                    <span className="material-icons text-secondary border-0 text-primary pe-2 md-36">account_circle</span>
                                                    <input type="text" className="form-control form-control-sm rounded-2 fw-light" placeholder="Write Your comment" />
                                                </div>
                                                <div className="comments">
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" >
                                                            <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="/profile" className="text-dark text-decoration-none" >
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">{detail.user[0].post[0].comments[0].user.name}</p>
                                                                    <span className="text-muted">{detail.user[0].post[0].comments[0].text}</span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">1h</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <Link to="#" className="text-dark text-decoration-none" >
                                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="commenters-img" />
                                                        </Link>
                                                        <div className="ms-2 small">
                                                            <Link to="/profile" className="text-dark text-decoration-none" >
                                                                <div className="bg-light px-3 py-2 rounded-2 mb-1 chat-text">
                                                                    <p className="fw-500 mb-0">{detail.user[0].post[0].comments[1].user.name}</p>
                                                                    <span className="text-muted">
                                                                        {detail.user[0].post[0].comments[1].text}
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                            <div className="d-flex align-items-center ms-2">
                                                                <Link to="#" className="small text-muted text-decoration-none">Like</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <Link to="#" className="small text-muted text-decoration-none">Reply</Link>
                                                                <span className="fs3 text-muted material-icons mx-1">circle</span>
                                                                <span className="small text-muted">20min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <Leftside/>

        </>
    );
}

export default Post;