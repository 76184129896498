import React from 'react';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';
import Autosuggest from 'react-autosuggest';

const languages = [
    {
      name: 'Narendra Modi'
    },
    {
      name: 'Amit Shah'
    },
    {
      name: 'Rahul Gandhi'
    },
    {
      name: 'Rajnath Singh'
    },
    {
      name: 'Yogi Adityanath'
    },
    {
      name: 'Arvind Kejriwal'
    },
    {
      name: 'Mamta Banerjee'
    },
    {
      name: 'Sonia Gandhi'
    },
    {
      name: 'Nitish Kumar'
    }
  ];

  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return languages.filter(language => regex.test(language.name));
  }

  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  function renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name}</span>
    );
  }

class Header extends React.Component{

    // constructor(props){
    //     super(props);
    //     this.state = {
    //         isClassActive: false,
    //     };
    // }
    // toggleClass = () => {
    //     this.setState({
    //         isClassActive: !this.state.isClassActive
    //     });
    // }

    constructor() {
        super();

        this.state = {
          value: '',
          suggestions: []
        };
      }

      onChange = (event, { newValue, method }) => {
        this.setState({
          value: newValue
        });
      };

      onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
          suggestions: getSuggestions(value)
        });
      };

      onSuggestionsClearRequested = () => {
        this.setState({
          suggestions: []
        });
      };

    componentDidMount(){
        let toggle = document.querySelector('aside');
        let main = document.querySelector('main');
        let body = document.querySelector('body');
        document.querySelector('.toggleMenu').onclick = function() {
            toggle.classList.toggle('sidebarOpen');
            // main.classList.toggle('mainSec');
            body.classList.toggle('mobileOverlay');
            $('.sidebarClose .menuName').toggleClass('menuNameShow');
            $('body').toggleClass('mobileOverlay');
        };
    }

    render(){
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search...",
            value,
            onChange: this.onChange
        };
        return(
            <>
                <div className="web-none d-flex align-items-center justify-content-start px-3 pt-3">
                    <button className="btn btn-primary ln-0 text-end toggleMenu" onClick={this.toggleClass} type="button">
                        <span className="material-icons">menu</span>
                    </button>
                    <Link to="/feed" className="text-decoration-none w-25 ps-3">
                        <img src={require("../../assets/img/logo.png")} className="img-fluid logo-mobile" alt="logo" />
                        {/* <img src={require('../../assets/img/logo.png').default} className="img-fluid logo-mobile" alt="brand-logo" /> */}
                    </Link>

                    <div className='searchbar w-50'>
                        {/* <div className="searchbarBlock input-group shadow-sm rounded-2 bg-white">
                            <span className="input-group-text material-icons border-0 bg-white text-secondary">search</span>
                            <Autosuggest className="input-group shadow-sm rounded-2 overflow-hidden bg-white"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                        </div> */}
                        {/* <div className="input-group shadow-sm rounded-2 overflow-hidden bg-white">
                            <span className="input-group-text material-icons border-0 bg-white text-secondary">search</span>
                            <input type="text" className="form-control border-0 fw-light ps-1" placeholder="Search " />
                        </div> */}
                    </div>


                </div>
            </>
        );
    }
}

export default Header;