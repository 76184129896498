import React from 'react';
import { Link } from 'react-router-dom';
import Leftside from '../../common/leftside/Leftside';
// import Rightside from '../../common/rightside/Rightside';
import * as $ from 'jquery';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import Select from 'react-select';
import Tagify from '@yaireo/tagify';

// const selectionRange = {
//     startDate: new Date(),
//     endDate: new Date(),
//     key: 'selection',
// }

// const options = [
//     { value: '1', label: 'Vadodara' },
//     { value: '2', label: 'Anand' },
//     { value: '3', label: '390001' },
//     { value: '4', label: '390011' },
//     { value: '5', label: 'Vapi' },
//     { value: '6', label: 'Mumbai' },
//     { value: '7', label: 'Delhi' }
// ]


class Profile extends React.Component{

    componentDidMount(){
        // Social plus button function
        $('#s-icons').on('click', function() {
            $('.navbar-nav').toggleClass("show");
        });

        var randomStringsArr = ["Tarsali", "Waghodia", "Alkapuri", "Gotri", "390009", "Gujarat", "Mumbai"];
        var input = document.querySelector('.serviceArea'),
            tagify = new Tagify(input, {
                whitelist: randomStringsArr,
                callbacks: {
                    "invalid": onInvalidTag
                },
                dropdown : {
                    position: 'text',
                    enabled: 1 // show suggestions dropdown after 1 typed character
                }
            }),
            button = input.nextElementSibling;  // "add new tag" action-button

        button.addEventListener("click", onAddButtonClick)

        function onAddButtonClick(){
            tagify.addEmptyTag()
        }

        function onInvalidTag(e){
            console.log("invalid", e.detail)
        }

        // table filter

        $("input[name='filterStatus']").on("change", function(){
            var val = $("input[name='filterStatus']:checked").map(function(){
                return $(this).val()
            }).get();

            $(".userProfileTable tbody tr").hide();

            var filter = $(".filter").filter(function(){
                var fil = $(this).text(),
                index = $.inArray(fil, val);
                console.log(fil);
                return index >= 0
            }).parent().show();
        })

        $("input[name='netaFilterStatus']").on("change", function(){
            var val = $("input[name='netaFilterStatus']:checked").map(function(){
                return $(this).val()
            }).get();

            $(".netaProfileTable tbody tr").hide();

            var filter = $(".filter").filter(function(){
                var fil = $(this).text(),
                index = $.inArray(fil, val);
                console.log(fil);
                return index >= 0
            }).parent().show();
        })
    }




    render(){
        return(
            <>
                <div className="main-content">
                    <div className="mb-4 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <Link to="/feed" className="material-icons text-dark text-decoration-none m-none me-3">arrow_back</Link>
                            <p className="ms-2 mb-0 fw-bold text-body fs-6">Anonymous User1</p>
                        </div>
                        {/* <div className="social-icons" id="s-icons">
                            <ul className="navbar-nav">
                                <li><a href="#" className="btn_social2 btn-social"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" className="btn_social2 btn-social"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#" className="btn_social2 btn-social"><i className="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#" className="btn_social2 btn-social"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                            <button className="btn_social2 btn-social" id="btn-share" data-toggle="tooltip" data-placement="left" title="Tooltip on left">
                            <i className="fa fa-share-alt"></i>
                            </button>
                        </div> */}
                    </div>


                    <div className="bg-white rounded-2 shadow-sm profile mb-4 p-2">
                        <ul className="userNetaTab top-osahan-nav-tab mb-2 nav nav-pills justify-content-center nav-justified shadow-sm rounded-2 overflow-hidden bg-white"
                            id="pills-tab" role="tablist">
                            <li className="nav-item w-25" role="presentation">
                                <button className="p-2 nav-link text-muted d-flex align-items-center justify-content-between w-100 active" id="pills-user-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-user" type="button" role="tab" aria-controls="pills-user"
                                    aria-selected="true">
                                        <div className="form-check d-flex align-items-center">
                                            <input type="checkbox" className="form-check-input" name="profile" id="user" value="checkedValue"/>
                                            <label className="form-check-label d-flex align-items-center">
                                                User / Citizen
                                            </label>
                                        </div>
                                        <button className='btn btn-secondary' data-bs-toggle="modal" data-bs-target="#updateProfile"><i className="fa-solid fa-pen-to-square"></i></button>
                                    </button>
                            </li>
                            <li className="nav-item w-50" role="presentation">
                                <button className="p-2 nav-link text-muted d-flex align-items-center justify-content-between w-100" id="pills-neta-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-neta" type="button" role="tab" aria-controls="pills-neta"
                                    aria-selected="false">
                                    <div className="form-check d-flex align-items-center">
                                        <input type="checkbox" className="form-check-input" name="profile" id="neta" value="checkedValue"/>
                                        <label className="form-check-label d-flex align-items-center">
                                            Neta
                                        </label>
                                    </div>
                                    <button className='btn btn-secondary text-right' data-bs-toggle="modal" data-bs-target="#updateNetaProfile"><i className="fa-solid fa-pen-to-square"></i></button>
                                </button>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab">
                                <div className="bg-white rounded-2 shadow-sm profile mb-4">
                                    <div className="d-flex align-items-center px-2 pt-3">
                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="profile-img"/>
                                        <div className="ms-3">
                                            <h6 className="mb-0 d-flex align-items-start text-body fs-6 fw-bold">Anonymous User1
                                                <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                            </h6>
                                            <p className="text-muted mb-0">@anonymoususer1</p>
                                        </div>
                                        <div className="ms-auto btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                            <input type="checkbox" className="btn-check" id="btncheck1"/>
                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck1">
                                                <span className="follow"><i className="fa-solid fa-plus"></i> Follow</span>
                                                <span className="following d-none">Following</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className='mb-1 font-bold underline text-2xl '><i className="fa-solid fa-user"></i> Ramesh Kumar</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className='mb-1'><i className="fa-solid fa-calendar-days"></i> 13-06-1989</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className='mb-1'><i className="fa-solid fa-phone"></i> +91 12345 67898</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className='mb-1'><i className="fa-solid fa-location-dot"></i> Vadodara, Gujarat, India</p>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/profile" className="text-decoration-none"><i className="fa-solid fa-link"></i> profile/anonymoususer1</Link>
                                            </div>
                                            <div className="col-md-6">
                                                <p className='mb-1'><i className="fa-solid fa-location-dot"></i> 390009</p>
                                            </div>

                                            <div className="col-md-6">
                                                <p><i className="fa-regular fa-calendar"></i> Joined on Feb 2022</p>
                                            </div>
                                        </div>

                                        <div className="d-flex followers mt-3">
                                            <div>
                                                <p className="mb-0"><strong>74 </strong><span className="text-muted">Issues Posted</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>
                                            <div className='ps-5' data-bs-toggle="modal" data-bs-target="#profileFollower">
                                                <p className="mb-0"><strong>391k </strong><span className="text-muted">Followers</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>
                                            <div className="ps-5" data-bs-toggle="modal" data-bs-target="#profileFollowing">
                                                <p className="mb-0"><strong>32K </strong><span className="text-muted">Following</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane border-0 fade show active" id="pills-pendingtask1" role="tabpanel" aria-labelledby="pills-pendingtask-tab1">
                                        <div className='d-block'>
                                            <div className="form-check me-3">
                                                <input type="checkbox" name="filterStatus" className="form-check-input" value="byMeVal" id="byMe" autocomplete="off" />
                                                <label className="btn btn-outline-secondary btn-sm" for="byMe"> Posts by Me(created by me)</label>
                                            </div>
                                            <div className="form-check">
                                                <input type="checkbox" name="filterStatus" className="form-check-input" value="byOtherVal" id="byOther" autocomplete="off" />
                                                <label className="btn btn-outline-secondary btn-sm" for="byOther"> Posts by Others (Following or Affected)</label>
                                            </div>
                                        </div>
                                        <div className="feeds">
                                            <div className='bg-white rounded-2 shadow-sm p-1'>
                                                <table className="table table-responsive  userProfileTable">
                                                    <thead className="thead-inverse">
                                                        <tr>
                                                            <th>Issue Desctiption</th>
                                                            <th>Affected Users</th>
                                                            <th>Tagged Netas</th>
                                                            <th>Commited Netas</th>
                                                            <th>Location</th>
                                                            <th>Review Proof (Reference Info./Material)</th>
                                                            <th>Solved Status</th>
                                                            <th>Results-%</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='filter d-none'>byMeVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small><br/>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>8 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileCommitedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>3 People</small>
                                                                </td>
                                                                <td>
                                                                    <p className="small">Waghodia, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-secondary btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Review</button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileSolvedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 Neta</small>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio1" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio1"><i className="fa-solid fa-thumbs-up"></i> 212 (65%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio2" autocomplete="off"/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio2"><i className="fa-solid fa-thumbs-down"></i> 87 (35%)</label>
                                                                    </div>
                                                                </td>
                                                                <td className='gap-2'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <button className='btn btn-outline-secondary btn-sm px-2 mr-2' data-bs-target="#editIssue" data-bs-toggle="modal">Edit</button>
                                                                        <button className='btn btn-outline-danger btn-sm px-2'>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='filter d-none'>byMeVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small><br/>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>3 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileCommitedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 People</small>
                                                                </td>
                                                                <td>
                                                                    <p className="small">Gotri, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-secondary btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Review</button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileSolvedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 Neta</small>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio2" id="mebtn-radio11" autocomplete="off"/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio11"><i className="fa-solid fa-thumbs-up"></i> 71 (35%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio2" id="mebtn-radio22" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio22"><i className="fa-solid fa-thumbs-down"></i> 140 (65%)</label>
                                                                    </div>
                                                                </td>
                                                                <td className='gap-2'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <button className='btn btn-outline-secondary btn-sm px-2 mr-2' data-bs-target="#editIssue" data-bs-toggle="modal">Edit</button>
                                                                        <button className='btn btn-outline-danger btn-sm px-2'>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='filter d-none'>byOtherVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small><br/>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>3 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileCommitedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 People</small>
                                                                </td>
                                                                <td>
                                                                    <p className="small">Gotri, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-secondary btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Review</button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileSolvedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 Neta</small>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio2" id="mebtn-radio11" autocomplete="off"/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio11"><i className="fa-solid fa-thumbs-up"></i> 71 (35%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio2" id="mebtn-radio22" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio22"><i className="fa-solid fa-thumbs-down"></i> 140 (65%)</label>
                                                                    </div>
                                                                </td>
                                                                <td className='gap-2'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <button className='btn btn-outline-secondary btn-sm px-2 mr-2' data-bs-target="#editIssue" data-bs-toggle="modal">Edit</button>
                                                                        <button className='btn btn-outline-danger btn-sm px-2'>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='filter d-none'>byMeVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small><br/>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>12 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileCommitedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>2 People</small>
                                                                </td>
                                                                <td>
                                                                    <p className="small">Alkapuri, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-secondary btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Review</button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileSolvedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>1 Neta</small>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio3" id="mebtn-radio111" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio111"><i className="fa-solid fa-thumbs-up"></i> 135 (65%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio3" id="mebtn-radio222" autocomplete="off"/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio222"><i className="fa-solid fa-thumbs-down"></i> 68 (35%)</label>
                                                                    </div>
                                                                </td>
                                                                <td className='gap-2'>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <button className='btn btn-outline-secondary btn-sm px-2 mr-2' data-bs-target="#editIssue" data-bs-toggle="modal">Edit</button>
                                                                        <button className='btn btn-outline-danger btn-sm px-2'>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="pills-neta" role="tabpanel" aria-labelledby="pills-people-tab">
                                <div className="bg-white rounded-2 shadow-sm profile mb-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='d-flex align-items-center px-2 pt-3'>
                                            <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="profile-img"/>
                                            <div className="ms-3">
                                                <h6 className="mb-0 d-flex align-items-start text-body fs-6 fw-bold">Anonymous Neta1
                                                    <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                </h6>
                                                <p className="text-muted mb-0">@anonymousneta1</p>
                                            </div>
                                        </div>
                                        <div className='text-right'>
                                            <div className="btn-group mr-2" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck2"/>
                                                <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck2">
                                                    <span className="follow"><i className="fa-solid fa-plus"></i> Support</span>
                                                    <span className="following d-none">Supporting</span>
                                                </label>
                                            </div>
                                            <div className="btn-group mr-2" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck3"/>
                                                <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck3">
                                                    <span className="follow"><i className="fa-solid fa-plus"></i> Follow</span>
                                                    <span className="following d-none">Following</span>
                                                </label>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                <input type="checkbox" className="btn-check" id="btncheck4"/>
                                                <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck4">
                                                    <span className="follow"><i className="fa-solid fa-plus"></i> Vote</span>
                                                    <span className="following d-none">Voting</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="mb-2 fs-6">The standard chunk of Lorem Ipsum used since is reproduced. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature..</p>
                                            </div>
                                            <div className="col-md-12">
                                                <Link to="/profile" className="text-decoration-none"><i className="fa-solid fa-link"></i> profile/anonymoususer1</Link>
                                            </div>
                                            <div className="col-md-12">
                                                <p><i className="fa-regular fa-calendar"></i> Joined on Feb 2022</p>
                                            </div>
                                        </div>
                                        <div className="d-flex followers">
                                            <div data-bs-toggle="modal" data-bs-target="#profileFollower">
                                                <p className="mb-0"><strong>391k </strong><span className="text-muted">Supporters</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>
                                            <div className="ps-5" data-bs-toggle="modal" data-bs-target="#profileFollower">
                                                <p className="mb-0"><strong>32K </strong><span className="text-muted">Following</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>

                                            <div className="ps-5" data-bs-toggle="modal" data-bs-target="#profileFollower">
                                                <p className="mb-0"><strong>21K </strong><span className="text-muted">Voting</span></p>
                                                <div className="d-flex">
                                                    <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="bg_orange">
                                            <p className="mb-2 text-orange"><strong>Selected Area</strong></p>
                                            <input className='serviceArea' placeholder='search area - Add your service area (city/village/state/country/zipcode,etc...)' value='Tarsali, Waghodia' />
                                            <div className='d-flex align-items-center'>
                                                <button className='btn addServiceArea' type="button"><i className="fa-solid fa-plus"></i> Areas of service</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="pills-commitedtask2" role="tabpanel" aria-labelledby="pills-commitedtask-tab2">
                                        <div className="feeds">
                                            <div className='bg-white rounded-2 shadow-sm p-3'>
                                                <div className='d-block'>
                                                    <div className="form-check me-3">
                                                        <input type="checkbox" name="netaFilterStatus" className="form-check-input" value="pendingIssuesVal" id="pendingIssues" autocomplete="off" />
                                                        <label className="btn btn-outline-secondary btn-sm" for="pendingIssues"> Recommeded / Pending Issues</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input type="checkbox" name="netaFilterStatus" className="form-check-input" value="acceptedIssueVal" id="acceptedIssue" autocomplete="off" />
                                                        <label className="btn btn-outline-secondary btn-sm" for="acceptedIssue"> Committed Issues / Accepted Issues</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input type="checkbox" name="netaFilterStatus" className="form-check-input" value="completedIssueVal" id="completedIssue" autocomplete="off" />
                                                        <label className="btn btn-outline-secondary btn-sm" for="completedIssue"> Completed Issues</label>
                                                    </div>
                                                </div>
                                                <table className="table table-responsive table-hover netaProfileTable">
                                                    <thead className="thead-inverse">
                                                        <tr>
                                                            <th>Issue Desctiption</th>
                                                            <th>Affected Users</th>
                                                            <th>Tagged Netas</th>
                                                            <th>Commited Netas</th>
                                                            <th>Action</th>
                                                            <th>Location</th>
                                                            <th>Progress status</th>
                                                            <th>Detail info.</th>
                                                            <th>In-progress Feedback</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='filter d-none'>acceptedIssueVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>

                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>14 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>4 People</small>
                                                                </td>
                                                                <td className='issueAction'>
                                                                    <button className='btn btn-success btn-sm px-2 withIcon mb-1' data-bs-target="#issueDate" data-bs-toggle="modal"><i className="fa-solid fa-circle-check"></i> Accepted/Commited</button>
                                                                    <button className='btn btn-outline-danger btn-sm px-2 withIcon' data-bs-target="#declineIssue" data-bs-toggle="modal"><i className="fa-solid fa-circle-xmark"></i> I Decline</button>
                                                                </td>
                                                                <td>
                                                                    <p className='small'>Alkapuri, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <select className='form-control small cSelect'>
                                                                        <option value="inprocess">In-progress</option>
                                                                        <option value="completed">Completed</option>
                                                                        <option value="recommeded">Recommeded</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-success btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Reference Info.</button>
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio1" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio1"><i className="fa-solid fa-thumbs-up"></i> 212 (65%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio2" autocomplete="off"/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio2"><i className="fa-solid fa-thumbs-down"></i> 87 (35%)</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='filter d-none'>pendingIssuesVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>7 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>4 People</small>
                                                                </td>
                                                                <td className='issueAction'>
                                                                    <button className='btn btn-outline-success btn-sm px-2 withIcon mb-1' data-bs-target="#issueDate" data-bs-toggle="modal"><i className="fa-solid fa-circle-check"></i> I Accept/Commit</button>
                                                                    <button className='btn btn-outline-danger btn-sm px-2 withIcon' data-bs-target="#" data-bs-toggle="modal"><i className="fa-solid fa-circle-xmark"></i> I Decline</button>
                                                                </td>
                                                                <td>
                                                                    <p className='small'>Waghodia, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <select className='form-control small cSelect inSelect'>
                                                                        <option value="inprocess">In-progress</option>
                                                                        <option value="completed">Completed</option>
                                                                        <option selected value="recommeded">Recommeded</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-success btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Reference Info.</button>
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio1" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio1"><i className="fa-solid fa-thumbs-up"></i> 45 (25%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio2" autocomplete="off"/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio2"><i className="fa-solid fa-thumbs-down"></i> 250 (75%)</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='filter d-none'>completedIssueVal</td>
                                                                <td scope="row"><Link to="/pfi32bid348a92harn574ad15ufa8abb">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptates</Link></td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileAffectedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>211 People</small>
                                                                    <button className="btn btn-secondary2 btn-sm" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>75 People</small>
                                                                </td>
                                                                <td className='followers' data-bs-toggle="modal" data-bs-target="#profileTaggedUser">
                                                                    <div className="d-flex">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <small>4 People</small>
                                                                </td>
                                                                <td className='issueAction'>
                                                                    <button className='btn btn-success btn-sm px-2 withIcon mb-1' data-bs-target="#issueDate" data-bs-toggle="modal"><i className="fa-solid fa-circle-check"></i> Accepted/Commited</button>
                                                                    <button className='btn btn-outline-danger btn-sm px-2 withIcon' data-bs-target="#declineIssue" data-bs-toggle="modal"><i className="fa-solid fa-circle-xmark"></i> I Decline</button>
                                                                </td>
                                                                <td>
                                                                    <p className='small'>Gotri, Vadodara</p>
                                                                </td>
                                                                <td>
                                                                    <select className='form-control small cSelect'>
                                                                        <option value="inprocess">In-progress</option>
                                                                        <option value="completed">Completed</option>
                                                                        <option value="recommeded">Recommeded</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-outline-success btn-sm px-2' data-bs-target="#detailInfo" data-bs-toggle="modal">Reference Info.</button>
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group-vertical" role="group">
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio1" autocomplete="off" checked/>
                                                                        <label className="btn btn-outline-success btn-sm" for="mebtn-radio1"><i className="fa-solid fa-thumbs-up"></i> 212 (65%)</label>
                                                                        <input type="radio" className="btn-check" name="mebtn-radio1" id="mebtn-radio2" autocomplete="off"/>
                                                                        <label className="btn btn-outline-danger btn-sm" for="mebtn-radio2"><i className="fa-solid fa-thumbs-down"></i> 87 (35%)</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mb-0 mt-2">Loading</p>
                </div>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Profile;