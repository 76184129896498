import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
import Rightside from '../../common/rightside/Rightside';

class Contact extends React.Component{
    render(){
        return(
            <>
                <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <div className="main-content">
                        <div className="mb-4 d-flex align-items-center">
                            <div className="feature bg-secondary bg-gradient text-white rounded-4 me-3">
                                <i className="icofont-envelope"></i>
                            </div>
                            <div>
                                <h1 className="fw-bold text-black mb-1">How can we help?</h1>
                                <p className="lead fw-normal text-muted mb-0">We'd love to hear from you</p>
                            </div>
                        </div>

                        <div className="feeds">
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm faq-page">
                                <div className="mb-3">
                                    <h5 className="lead fw-bold text-body mb-0">Contact Form</h5>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <form className="form-floating-space" id="contactForm">

                                            <div className="form-floating mb-3">
                                                <input className="form-control rounded-5" id="name" type="text"
                                                    placeholder="Enter your name..." data-sb-validations="required" />
                                                <label for="name">Full name</label>
                                                <div className="invalid-feedback" data-sb-feedback="name:required">A name is
                                                    required.</div>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input className="form-control rounded-5" id="email" type="email"
                                                    placeholder="name@example.com"
                                                    data-sb-validations="required,email" />
                                                <label for="email">Email address</label>
                                                <div className="invalid-feedback" data-sb-feedback="email:required">An email
                                                    is required.</div>
                                                <div className="invalid-feedback" data-sb-feedback="email:email">Email is
                                                    not valid.</div>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input className="form-control rounded-5" id="phone" type="tel"
                                                    placeholder="(123) 456-7890" data-sb-validations="required" />
                                                <label for="phone">Phone number</label>
                                                <div className="invalid-feedback" data-sb-feedback="phone:required">A phone
                                                    number is required.</div>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <textarea className="form-control rounded-5" id="message" type="text"
                                                    placeholder="Enter your message here..."
                                                    data-sb-validations="required"></textarea>
                                                <label for="message">Message</label>
                                                <div className="invalid-feedback" data-sb-feedback="message:required">A
                                                    message is required.</div>
                                            </div>




                                            <div className="d-none" id="submitSuccessMessage">
                                                <div className="text-center mb-3">
                                                    <div className="fw-bolder">Form submission successful!</div>
                                                    To activate this form, sign up at
                                                </div>
                                            </div>




                                            <div className="d-none" id="submitErrorMessage">
                                                <div className="text-center text-danger mb-3">Error sending message!</div>
                                            </div>

                                            <div className="d-grid"><button
                                                    className="btn btn-secondary w-100 rounded-5 text-decoration-none py-3 fw-bold text-uppercase m-0"
                                                    id="submitButton" type="submit">Submit</button></div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row row-cols-2 row-cols-lg-4 pt-5">
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-chat"></i></div>
                                        <div className="h6 mb-2 fw-bold text-black">Chat with us</div>
                                        <p className="text-muted mb-0">Chat live with one of our support specialists.</p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-people"></i></div>
                                        <div className="h6 fw-bold text-black">Ask the community</div>
                                        <p className="text-muted mb-0">Explore our community forums and communicate with
                                            other users.</p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-question-circle"></i></div>
                                        <div className="h6 fw-bold text-black">Support center</div>
                                        <p className="text-muted mb-0">Browse FAQ's and support articles to find solutions.
                                        </p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-telephone"></i></div>
                                        <div className="h6 fw-bold text-black">Call us</div>
                                        <p className="text-muted mb-0">Call us during normal business hours at 1236547895.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Contact;