import React from 'react';

import ReactApexChart from "react-apexcharts";

import Select from 'react-select';
const selectoptions = [
    { value: '1', label: 'Vadodara' },
    { value: '2', label: 'Anand' },
    { value: '3', label: '390001' },
    { value: '4', label: '390011' },
    { value: '5', label: 'Vapi' },
    { value: '6', label: 'Mumbai' },
    { value: '7', label: 'Delhi' }
]


function Chartsec(){

    // chart data
    const apexoptions = {
        legend: {
            position: 'top',
            offsetX: -10,
            offsetY: 0
        },
        colors: ['#6c757d', '#ccc'],
        fill: {
            opacity: 1,
        },
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                },
                chart: {
                    height: 350,
                    stacked: true,
                }
            }
        }],
        xaxis: {
            categories: ["Yes", "No", "May be", "None of above"]
        }
    };
    const series = [
        {
            name: "Male",
            data: [66, 104, 45, 74]
        },
        {
            name: "Female",
            data: [37, 87, 54, 50]
        }
    ];


    return(
        <>
            <div className='chartSection rounded-2'>
                <div className="w-70 m_w_100 overflow-hidden">
                    <div className="row mb-2 g-2">
                        <div className="col-md-3 col-6">
                            <select className='form-select me-2 mb-1'>
                                <option id='bar' value="bar">Bar Chart</option>
                            </select>
                        </div>

                        <div className="col-md-3 col-6">
                            <select className='form-select me-2 mb-1'>
                                <option defaultValue="">Age Group</option>
                                <option value="1">18-25</option>
                                <option value="2">26-35</option>
                                <option value="3">36-50</option>
                                <option value="3">51-65</option>
                            </select>
                        </div>

                        <div className="col-md-3 col-6">
                            <select className='form-select me-2 mb-1'>
                                <option defaultValue="">Time</option>
                                <option value="1">1 day</option>
                                <option value="2">Week</option>
                                <option value="3">15 Days</option>
                                <option value="3">Month</option>
                                <option value="4">3 Months</option>
                                <option value="6">6 Months</option>
                            </select>
                        </div>

                        <div className="col-md-3 col-6">
                            <Select className='pollLocation w-100 mb-1' options={selectoptions} />
                        </div>
                    </div>
                    <ReactApexChart options={apexoptions} series={series} type="bar" />
                </div>

                <div className='w-30 m_w_100'>
                    <div className="row g-2">
                        <div className="col-md-12 col-6">
                            <div className='showOptions'>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color1'>100%</span>
                                    <span className='option'>All</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color1'>30%</span>
                                    <span className='option'>Yes</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color2'>20%</span>
                                    <span className='option'>No</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color3'>40%</span>
                                    <span className='option'>May be</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color4'>10%</span>
                                    <span className='option'>None of above</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className='ageGroup'>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color1'>40%</span>
                                    <span className='option'>18-25</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color2'>30%</span>
                                    <span className='option'>26-35</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color3'>20%</span>
                                    <span className='option'>36-50</span>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <span className='sqaure color4'>10%</span>
                                    <span className='option'>50-70</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className='genderRatio'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'><i className="fa-solid fa-person fa-2x"></i></div>
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <span className='option'>50%</span>
                                        <span className='option'>(16k)</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <div className='me-2'><i className="fa-solid fa-person-dress fa-2x"></i></div>
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <span className='option'>30%</span>
                                        <span className='option'>(9k)</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    <div className='me-2'><i className="fa-solid fa-person-dress fa-2x"></i></div>
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <span className='option'>20%</span>
                                        <span className='option'>(3k)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}


export default Chartsec;