import React from 'react';
import { Link } from 'react-router-dom';



class Footer extends React.Component{
    render(){
        return(
            <>
                <footer className="py-3 bg-white footer-copyright">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <span className="me-3 small">©2022 <b className="text-primary">NextNeta</b>. All rights reserved</span>
                            </div>
                            <div className="col-md-4 text-end">
                                <Link target="_blank" to="#" className="btn social-btn btn-sm text-decoration-none"><i className="icofont-facebook"></i></Link>
                                <Link target="_blank" to="#" className="btn social-btn btn-sm text-decoration-none"><i className="icofont-twitter"></i></Link>
                                <Link target="_blank" to="#" className="btn social-btn btn-sm text-decoration-none"><i className="icofont-linkedin"></i></Link>
                                <Link target="_blank" to="#" className="btn social-btn btn-sm text-decoration-none"><i className="icofont-youtube-play"></i></Link>
                                <Link target="_blank" to="#" className="btn social-btn btn-sm text-decoration-none"><i className="icofont-instagram"></i></Link>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;