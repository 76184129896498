import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
import Rightside from '../../common/rightside/Rightside';

class Editprofile extends React.Component{
    render(){
        return(
            <>
                <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <div className="main-content">
                        <div className="mb-5">
                            <header className="profile d-flex align-items-center">
                                <img alt="#" src={require('../../assets/img/rmate2.jpg')} className="rounded-circle me-3"/>
                                <div>
                                    <span className="text-muted text_short">WELCOME 👋</span>
                                    <h4 className="mb-0 text-dark"><span className="fw-bold">Anonymous User1</span></h4>
                                </div>
                            </header>
                        </div>

                        <div className="feeds">
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm mb-3 faq-page">
                                <div className="mb-3">
                                    <h5 className="lead fw-bold text-body mb-0">Edit Profile</h5>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <form action="https://askbootstrap.com/preview/vogel/profile.html">
                                            <div className="form-floating mb-3 d-flex align-items-end">
                                                <input type="text" className="form-control rounded-5" id="floatingName"
                                                    defaultValue="Anonymous User1" placeholder="Name"/>
                                                <label for="floatingName">NAME</label>
                                            </div>
                                            <div className="form-floating mb-3 d-flex align-items-end">
                                                <input type="text" className="form-control rounded-5" id="floatingName"
                                                    defaultValue="@anonymoususer1" placeholder="Username"/>
                                                <label for="floatingName">USERNAME</label>
                                            </div>
                                            <div className="form-floating mb-3 d-flex align-items-center">
                                                <input type="text" className="form-control rounded-5" id="floatingBirth"
                                                    defaultValue="18/02/1997" placeholder="Date of Birth"/>
                                                <label for="floatingBirth">DATE OF BIRTH</label>
                                            </div>
                                            <div className="form-floating mb-3 d-flex align-items-center">
                                                <input type="email" className="form-control rounded-5" id="floatingEmail"
                                                    placeholder="Email" defaultValue="anonymoususer1@gmail.com"/>
                                                <label for="floatingEmail">EMAIL</label>
                                            </div>
                                            <div className="form-floating mb-3 d-flex align-items-center">
                                                <input type="password" className="form-control rounded-5" id="floatingPass"
                                                    placeholder="password" defaultValue="12345678"/>
                                                <label for="floatingPass">PASSWORD</label>
                                            </div>
                                            <label className="mb-2 text-muted small">GENDER</label>
                                            <div className="d-flex align-items-center mb-3 px-0">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="male"/>
                                                    <label className="form-check-label" for="male">
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check mx-3">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="female" checked/>
                                                    <label className="form-check-label" for="female">
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="not"/>
                                                    <label className="form-check-label" for="not">
                                                        Prefer not to say
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-grid">
                                                <button className="btn btn-primary rounded-5 w-100 text-decoration-none py-3 fw-bold text-uppercase m-0">SAVE</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm faq-page mb-3">
                                <div className="mb-3">
                                    <h5 className="lead fw-bold text-body mb-0">Confirm your password </h5>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <form action="https://askbootstrap.com/preview/vogel/profile.html">
                                            <div className="form-floating mb-3 d-flex align-items-center">
                                                <input type="password" className="form-control rounded-5" id="floatingPass"
                                                    placeholder="password" defaultValue="12345678"/>
                                                <label for="floatingPass">PASSWORD</label>
                                            </div>
                                            <div className="d-grid">
                                                <button
                                                    className="btn btn-primary w-100 text-decoration-none rounded-5 py-3 fw-bold text-uppercase m-0">Confirm</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm faq-page">
                                <div className="mb-3">
                                    <h5 className="lead fw-bold text-body mb-0">APP SETTINGS
                                    </h5>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <p className="pt-3 d-flex align-items-center mb-0">
                                            <span>NOTIFICATIONS</span>
                                            <span className="ms-auto form-check form-switch">
                                                <input className="form-check-input mt-2 ms-0" type="checkbox" id="flexSwitchCheckDsefault"/>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Editprofile;