import React from 'react';
import './assets/css/style.css';
import './assets/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/vendor/slick/slick/slick.css';
import './assets/vendor/slick/slick/slick-theme.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/icons/css/materialdesignicons.min.css';
// import '@mui/icons-material';
// import * as $ from 'jquery';
// import './assets/vendor/slick/slick/slick.min.js';
import 'slick-carousel';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Feed from './component/feed/Feed';
import Post from './component/feed/Post';
import Header from './common/header/Header';
import Footer from './common/footer/Footer';
import Profile from './component/profile/Profile';
import Editprofile from './component/editprofile/Editprofile';
import Explore from './component/explore/Explore';
import Trending from './component/tranding/Trending';
import Notification from './component/notification/Notification';
import Contact from './component/contact/Contact';
import Faq from './component/faq/Faq';
import About from './component/about/About';

function App(){
    return (
      <>
        <Router>
          <Header/>
            <div className="py-3">
              <div className="container-fluid">
                <div className="row position-relative">
                  <main className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mainSec">
                    <Routes>
                      <Route path="/" element={<Feed />} />
                      <Route path="/feed" element={<Feed />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/edit-profile" element={<Editprofile />} />
                      <Route path="/explore" element={<Explore />} />
                      <Route path="/trending" element={<Trending />} />
                      <Route path="/notification" element={<Notification />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/faq" element={<Faq />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/pfi32bid348a92harn574ad15ufa8abb" element={<Post />} />
                    </Routes>
                  </main>
                </div>
              </div>
            </div>
          <Footer/>
        </Router>
      </>
    );
  }

export default App;
