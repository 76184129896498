import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
import Rightside from '../../common/rightside/Rightside';

class About extends React.Component{
    render(){
        return(
            <>
                <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <div className="main-content">
                        <div className="mb-4 d-flex align-items-center">
                            <div className="feature bg-secondary bg-gradient text-white rounded-4 me-3">
                                <i className="icofont-envelope"></i>
                            </div>
                            <div>
                                <h1 className="fw-bold text-black mb-1">About Us</h1>
                                <p className="lead fw-normal text-muted mb-0">What we do?</p>
                            </div>
                        </div>

                        <div className="feeds">
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm faq-page">
                                <div className="mb-3">
                                    <h5 className="lead fw-bold text-body mb-0">About Nextneta</h5>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit corporis harum totam soluta impedit? Veniam numquam eaque doloribus nobis tempore impedit sequi, libero quas atque fuga officiis rerum delectus eveniet.</p>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste perferendis asperiores nulla ipsa dignissimos, rem placeat autem magni recusandae laudantium. Labore nulla distinctio amet iusto voluptatem earum! Necessitatibus, beatae ratione?</p>
                                    </div>
                                </div>
                                <div className="row row-cols-2 row-cols-lg-4 pt-5">
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-chat"></i></div>
                                        <div className="h6 mb-2 fw-bold text-black">Chat with us</div>
                                        <p className="text-muted mb-0">Chat live with one of our support specialists.</p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-people"></i></div>
                                        <div className="h6 fw-bold text-black">Ask the community</div>
                                        <p className="text-muted mb-0">Explore our community forums and communicate with
                                            other users.</p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-question-circle"></i></div>
                                        <div className="h6 fw-bold text-black">Support center</div>
                                        <p className="text-muted mb-0">Browse FAQ's and support articles to find solutions.
                                        </p>
                                    </div>
                                    <div className="col">
                                        <div className="feature bg-secondary bg-gradient text-white rounded-4 mb-3"><i
                                                className="icofont-telephone"></i></div>
                                        <div className="h6 fw-bold text-black">Call us</div>
                                        <p className="text-muted mb-0">Call us during normal business hours at  .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default About;