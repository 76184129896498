import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
import Rightside from '../../common/rightside/Rightside';

class Explore extends React.Component{
    render(){
        return(
            <>
                <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <div className="main-content">
                        <div className="d-flex align-items-center mb-3">
                            <a href="/feed" className="material-icons text-dark text-decoration-none m-none me-3">arrow_back</a>
                            <p className="ms-2 mb-0 fw-bold text-body fs-6">Explore</p>
                        </div>

                        <div className="bg-white rounded-4 overflow-hidden shadow-sm mb-4 mb-lg-0">
                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>COVID-19</small><span
                                            className="mx-1 material-icons md-3">circle</span><small>Live</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">India records 2,541 fresh COVID-19 cases, 30 deaths</p>
                                    <small className="text-muted">Trending with</small><br />
                                </div>
                            </a>

                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">CEO of Twitter</p>
                                    <small className="text-muted">CEO of Twitter</small><br />
                                </div>
                            </a>

                            <div className="p-3 border-bottom d-flex">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>Trending in India</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3 text-dark">#ME11Lite</p>
                                    <small className="text-muted">Discere constituto vituperatoribus ad vis, in pri atqui
                                        animal assueverit</small><br />
                                    <small className="text-muted">52.8k Tweets</small>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a href="#"
                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                        id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">more_vert</a>
                                    <ul className="dropdown-menu fs-13 dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton2">
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>Not
                                                interested in this</a></li>
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>This
                                                trend is harmful or spammy</a></li>
                                    </ul>
                                </div>
                            </div>

                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>COVID-19</small><span
                                            className="mx-1 material-icons md-3">circle</span><small>Live</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">India records 2,541 fresh COVID-19 cases, 30 deaths</p>
                                    <small className="text-muted">Trending with</small><br />
                                </div>
                            </a>

                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">CEO of Twitter</p>
                                    <small className="text-muted">CEO of Twitter</small><br />
                                </div>
                            </a>

                            <div className="p-3 border-bottom d-flex">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>Trending in India</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3 text-dark">#ME11Lite</p>
                                    <small className="text-muted">Discere constituto vituperatoribus ad vis, in pri atqui
                                        animal assueverit</small><br />
                                    <small className="text-muted">52.8k Tweets</small>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a href="#"
                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                        id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">more_vert</a>
                                    <ul className="dropdown-menu fs-13 dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton2">
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>Not
                                                interested in this</a></li>
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>This
                                                trend is harmful or spammy</a></li>
                                    </ul>
                                </div>
                            </div>

                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>COVID-19</small><span
                                            className="mx-1 material-icons md-3">circle</span><small>Live</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">India records 2,541 fresh COVID-19 cases, 30 deaths</p>
                                    <small className="text-muted">Trending with</small><br />
                                </div>
                            </a>

                            <a href="/tags"
                                className="p-3 border-bottom d-flex align-items-center text-dark text-decoration-none">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                    </div>
                                    <p className="fw-bold mb-0 pe-3">CEO of Twitter</p>
                                    <small className="text-muted">CEO of Twitter</small><br />
                                </div>
                            </a>

                            <div className="p-3 border-bottom d-flex">
                                <div>
                                    <div className="text-muted fw-light d-flex align-items-center">
                                        <small>Trending in India</small>
                                    </div>
                                    <p className="fw-bold mb-0 pe-3 text-dark">#ME11Lite</p>
                                    <small className="text-muted">Discere constituto vituperatoribus ad vis, in pri atqui
                                        animal assueverit</small><br />
                                    <small className="text-muted">52.8k Tweets</small>
                                </div>
                                <div className="dropdown ms-auto">
                                    <a href="#"
                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                        id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">more_vert</a>
                                    <ul className="dropdown-menu fs-13 dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton2">
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>Not
                                                interested in this</a></li>
                                        <li><a className="dropdown-item text-muted" href="#"><span
                                                    className="material-icons md-13 me-1">sentiment_very_dissatisfied</span>This
                                                trend is harmful or spammy</a></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </main>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Explore;