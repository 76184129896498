import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
// import Rightside from '../../common/rightside/Rightside';

class Notification extends React.Component{
    render(){
        return(
            <>
                <div className="main-content">
                    <ul className="top-osahan-nav-tab nav nav-pills justify-content-center nav-justified shadow-sm rounded-2 overflow-hidden bg-white sticky-sidebar2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="p-2 nav-link text-muted active" id="pills-area-tab" data-bs-toggle="pill" data-bs-target="#pills-area" type="button" role="tab" aria-controls="pills-area" aria-selected="true">Action on Complaint</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="p-2 nav-link text-muted" id="pills-city-tab" data-bs-toggle="pill" data-bs-target="#pills-city" type="button" role="tab" aria-controls="pills-city" aria-selected="false">Notification</button>
                        </li>
                    </ul>


                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-area" role="tabpanel" aria-labelledby="pills-area-tab">
                            {/* <div className="pt-4 feeds">
                                <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm">
                                    <div class="list-group">
                                        <Link to="#" className="list-group-item list-group-item-action" aria-current="true">
                                            <div>
                                                <p class="mb-1"><strong>Neta 1</strong> is working on your task.</p>
                                                <small>5 min ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action">
                                            <div>
                                                <p class="mb-1"><strong>Neta 2</strong> is working on your task.</p>
                                                <small>5 min ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action">
                                            <div>
                                                <p class="mb-1"><strong>Neta 3</strong> is working on your task.</p>
                                                <small>1 hr ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action">
                                            <div>
                                                <p class="mb-1"><strong>Neta 4</strong> is working on your task.</p>
                                                <small>1 hr ago</small>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                            <div class="card-body p-2">
                                <ul class="list-unstyled">
                                    <li>
                                        <div class="rounded bg-white badge-unread border-0 d-sm-flex align-items-start mb-1 p-2 position-relative">
                                            <div className='d-sm-flex align-items-center'>
                                                <div class="avatar text-center">
                                                    <img class="avatar-img rounded-circle img-fluid rounded-circle user-img" src={require('../../assets/img/rmate2.jpg')} alt=""/>
                                                </div>
                                                <div class="mx-sm-3 my-2 my-sm-0">
                                                    <p class="small mb-2"><b>Anonymous User1</b> tagged in Issue.</p>
                                                    <div class="d-flex">
                                                        <button class="btn btn-sm py-1 btn-secondary me-2">Accept </button>
                                                        <button class="btn btn-sm py-1 btn-outline-danger">Ignore </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex ms-auto align-items-center">
                                                <p class="small m-0">Just now</p>
                                                <div className="dropdown">
                                                    <Link
                                                        to="#"
                                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        more_vert
                                                    </Link>
                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Delete</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Turn Off</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Mute User</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="rounded bg-white badge-unread border-0 d-sm-flex align-items-start mb-1 p-2 position-relative">
                                            <div className='d-sm-flex align-items-center'>
                                                <div class="avatar text-center">
                                                    <img class="avatar-img rounded-circle img-fluid rounded-circle user-img" src={require('../../assets/img/rmate2.jpg')} alt=""/>
                                                </div>
                                                <div class="mx-sm-3 my-2 my-sm-0">
                                                    <p class="small mb-2"><b>Anonymous User1</b> tagged in Issue.</p>
                                                    <div class="d-flex">
                                                        <button class="btn btn-sm py-1 btn-secondary me-2">Accept </button>
                                                        <button class="btn btn-sm py-1 btn-outline-danger">Ignore </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex ms-auto align-items-center">
                                                <p class="small m-0">Just now</p>
                                                <div className="dropdown">
                                                    <Link
                                                        to="#"
                                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        more_vert
                                                    </Link>
                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Delete</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Turn Off</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Mute User</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="rounded bg-white badge-unread border-0 d-sm-flex align-items-start mb-1 p-2 position-relative">
                                            <div className='d-sm-flex align-items-center'>
                                                <div class="avatar text-center">
                                                    <img class="avatar-img rounded-circle img-fluid rounded-circle user-img" src={require('../../assets/img/rmate2.jpg')} alt=""/>
                                                </div>
                                                <div class="mx-sm-3 my-2 my-sm-0">
                                                    <p class="small mb-2"><b>Anonymous User1</b> tagged in Issue.</p>
                                                    <div class="d-flex">
                                                        <button class="btn btn-sm py-1 btn-secondary me-2">Accept </button>
                                                        <button class="btn btn-sm py-1 btn-outline-danger">Ignore </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex ms-auto align-items-center">
                                                <p class="small m-0">Just now</p>
                                                <div className="dropdown">
                                                    <Link
                                                        to="#"
                                                        className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        more_vert
                                                    </Link>
                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">delete</span>Delete</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Turn Off</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Mute User</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-city" role="tabpanel" aria-labelledby="pills-city-tab">
                            <div className="feeds pt-4">
                                <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm">
                                    <div class="list-group">
                                        <Link to="#" className="list-group-item list-group-item-action" aria-current="true">
                                            <div>
                                                <p class="mb-1"><strong>anonymous 1</strong> Commented on your post</p>
                                                <small>10 min ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action" aria-current="true">
                                            <div>
                                                <p class="mb-1"><strong>anonymous 2</strong> Commented on your post</p>
                                                <small>15 min ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action" aria-current="true">
                                            <div>
                                                <p class="mb-1"><strong>anonymous 3</strong> Commented on your post</p>
                                                <small>1 hr ago</small>
                                            </div>
                                        </Link>
                                        <Link to="#" className="list-group-item list-group-item-action" aria-current="true">
                                            <div>
                                                <p class="mb-1"><strong>anonymous 4</strong> Commented on your post</p>
                                                <small>1 hr ago</small>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Notification;