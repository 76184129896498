import React from 'react';
import { Link } from 'react-router-dom';

import Leftside from '../../common/leftside/Leftside';
import Rightside from '../../common/rightside/Rightside';

class Faq extends React.Component{
    render(){
        return(
            <>
                <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <div className="main-content">
                        <div className="mb-4 d-flex align-items-center">
                            <div className="feature bg-secondary bg-gradient text-white rounded-4 me-3">
                                <i className="mdi mdi-help"></i>
                            </div>
                            <div>
                                <h1 className="fw-bold text-black mb-1">Frequently Asked Questions</h1>
                                <p className="lead fw-normal text-muted mb-0">How can we help you?</p>
                            </div>
                        </div>

                        <div className="feeds">
                            <div className="bg-white p-4 feed-item rounded-4 shadow-sm faq-page">
                                <div className="rounded-3">
                                    <div className="mb-3">
                                        <h5 className="lead fw-bold text-body mb-0">Basics</h5>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="accordion overflow-hidden bg-white" id="basic">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="basic1"><button
                                                            className="accordion-button collapsed fw-bold" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#basic1"
                                                            aria-expanded="false" aria-controls="basic1">How can I
                                                            control if other search engines can link to my
                                                            profile?</button></h3>
                                                    <div className="accordion-collapse collapse" id="basic1"
                                                        aria-labelledby="basic1"
                                                        data-bs-parent="#basic">
                                                        <div className="accordion-body">
                                                            <strong>This is the second item's accordion body.</strong>
                                                            It is hidden by default, until the collapse plugin adds the
                                                            appropriate classNamees that we use to style each element. These
                                                            classNamees control the overall appearance, as well as the
                                                            showing and hiding via CSS transitions. You can modify any
                                                            of this with custom CSS or overriding our default variables.
                                                            It's also worth noting that just about any HTML can go
                                                            within the
                                                            <code>.accordion-body</code>
                                                            , though the transition does limit overflow.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="basic"><button
                                                            className="accordion-button fw-bold m-0" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#basic2"
                                                            aria-expanded="true" aria-controls="basic2">How do I
                                                            change my password?</button></h3>
                                                    <div className="accordion-collapse collapse show" id="basic2"
                                                        aria-labelledby="basic"
                                                        data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <p className="m-0">Ppropriate classNamees that we use to style each
                                                                Anim pariatur cliche reprehenderit, enim eiusmod high
                                                                life accusamus terry richardson ad squid. 3 wolf moon
                                                                officia aute, non cupidatat skateboard dolor brunch.
                                                                Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                                                                wolf moon tempor, sunt aliqua put a bird on it squid
                                                                single-origin coffee nulla assumenda shoreditch et.
                                                                Nihil anim keffiyeh helvetica, craft beer labore wes
                                                                anderson cred nesciunt sapiente ea proident. Ad vegan
                                                                excepteur butcher vice lomo. Leggings occaecat craft
                                                                beer farm-to-table, raw denim aesthetic synth nesciunt
                                                                you probably haven't heard of them accusamus labore
                                                                sustainable.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4 pt-2">
                                        <h5 className="lead fw-bold text-body mb-0">Account</h5>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="accordion overflow-hidden bg-white" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="headingTwo4"><button
                                                            className="accordion-button collapsed fw-bold" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo4"
                                                            aria-expanded="false" aria-controls="collapseTwo4">How can I
                                                            control if other search engines can link to my
                                                            profile?</button></h3>
                                                    <div className="accordion-collapse collapse" id="collapseTwo4"
                                                        aria-labelledby="headingTwo4"
                                                        data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <strong>This is the second item's accordion body.</strong>
                                                            It is hidden by default, until the collapse plugin adds the
                                                            appropriate classNamees that we use to style each element. These
                                                            classNamees control the overall appearance, as well as the
                                                            showing and hiding via CSS transitions. You can modify any
                                                            of this with custom CSS or overriding our default variables.
                                                            It's also worth noting that just about any HTML can go
                                                            within the
                                                            <code>.accordion-body</code>
                                                            , though the transition does limit overflow.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="headingOne1"><button
                                                            className="accordion-button fw-bold m-0" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne1"
                                                            aria-expanded="true" aria-controls="collapseOne1">How do I
                                                            change my password?</button></h3>
                                                    <div className="accordion-collapse collapse show" id="collapseOne1"
                                                        aria-labelledby="headingOne1"
                                                        data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <p className="m-0">Ppropriate classNamees that we use to style each
                                                                Anim pariatur cliche reprehenderit, enim eiusmod high
                                                                life accusamus terry richardson ad squid. 3 wolf moon
                                                                officia aute, non cupidatat skateboard dolor brunch.
                                                                Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                                                                wolf moon tempor, sunt aliqua put a bird on it squid
                                                                single-origin coffee nulla assumenda shoreditch et.
                                                                Nihil anim keffiyeh helvetica, craft beer labore wes
                                                                anderson cred nesciunt sapiente ea proident. Ad vegan
                                                                excepteur butcher vice lomo. Leggings occaecat craft
                                                                beer farm-to-table, raw denim aesthetic synth nesciunt
                                                                you probably haven't heard of them accusamus labore
                                                                sustainable.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="headingTwo2"><button
                                                            className="accordion-button collapsed fw-bold" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo2"
                                                            aria-expanded="false" aria-controls="collapseTwo2">How do I
                                                            delete my account?</button></h3>
                                                    <div className="accordion-collapse collapse" id="collapseTwo2"
                                                        aria-labelledby="headingTwo2"
                                                        data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <strong>This is the second item's accordion body.</strong>
                                                            It is hidden by default, until the collapse plugin adds the
                                                            appropriate classNamees that we use to style each element. These
                                                            classNamees control the overall appearance, as well as the
                                                            showing and hiding via CSS transitions. You can modify any
                                                            of this with custom CSS or overriding our default variables.
                                                            It's also worth noting that just about any HTML can go
                                                            within the
                                                            <code>.accordion-body</code>
                                                            , though the transition does limit overflow.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="headingThree3"><button
                                                            className="accordion-button collapsed fw-bold" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseThree3"
                                                            aria-expanded="false" aria-controls="collapseThree3">How do
                                                            I change my account settings?</button></h3>
                                                    <div className="accordion-collapse collapse" id="collapseThree3"
                                                        aria-labelledby="headingThree3"
                                                        data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <strong>This is the third item's accordion body.</strong>
                                                            It is hidden by default, until the collapse plugin adds the
                                                            appropriate classNamees that we use to style each element. These
                                                            classNamees control the overall appearance, as well as the
                                                            showing and hiding via CSS transitions. You can modify any
                                                            of this with custom CSS or overriding our default variables.
                                                            It's also worth noting that just about any HTML can go
                                                            within the
                                                            <code>.accordion-body</code>
                                                            , though the transition does limit overflow.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Leftside/>
                {/* <Rightside/> */}
            </>
        );
    }
}

export default Faq;