import { createServer } from "miragejs"

createServer({
    routes() {
        this.namespace = "/api"

        this.get("/users", () => {
            return {
                users: [
                    { id: 1, name: "Anonymous User1", username: "anonymous1" },
                ],
            }
        })

        this.get("/posts", () => {
            return {
                posts: [
                    {
                        id: 1,
                        que: "Online Eduction or Offline Education?",
                        options: [
                            {
                                id: 1,
                                option: "Yes",
                                vote: 30
                            },
                            {
                                id: 2,
                                option: "No",
                                vote: 20
                            },
                            {
                                id: 3,
                                option: "May be",
                                vote: 40
                            },
                            {
                                id: 4,
                                option: "None of above",
                                vote: 10
                            }
                        ],
                        date:"20 Apr",
                        location: "Waghodia, Vadodara",
                        affectedUser: "21k",
                        commentCount: "5k",
                        share: "2k",
                        comments:[
                            {
                                id: 1,
                                name:"Anonymous User2",
                                comment:"Comment1"
                            },
                            {
                                id: 2,
                                name:"Anonymous User3",
                                comment:"Comment2"
                            }
                        ],
                        taggedUser: '211 People',
                        commitedNetas: '12 Netas',
                        netaWhoSolved: '3 Netas'
                    },
                ],
            }
        })
    },
})