import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';
import Slider from "react-slick";
import ChartSec from '../../component/feed/Chartsec';
import Tagify from '@yaireo/tagify';
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import "react-datepicker/dist/react-datepicker.css";
import allData from '../../mock/allData.json';


class Leftside extends React.Component{

    constructor() {
        super();
        this.state = {
            options: [{ name: 'Option 1' }, { name: 'Option 2' }],
            acceptDate1: new Date(),
            resolveDate1: new Date()
        };

        this.ref = {
            references: [{ name: 'Option 1' }, { name: 'Option 2' }],
        };
        $('fbSlider').slick();

        // calender
        // this.state = {
        //     startDate: new Date()
        // };
        this.acceptDate = this.acceptDate.bind(this);
        this.resolveDate = this.resolveDate.bind(this);
        // this.onFormSubmit = this.onFormSubmit.bind(this);
    }
    acceptDate(date) {
        this.setState({
            acceptDate1: date
        })
    }
    resolveDate(date) {
        this.setState({
            resolveDate1: date
        })
    }

    handleOptionNameChange = (idx) => (evt) => {
        const newOptions = this.state.options.map((option, sidx) => {
            if (idx !== sidx) return option;
            return { ...option, name: evt.target.value };
        });

        this.setState({ options: newOptions });
    }

    handleSubmit = (evt) => {
        const { name, options } = this.state;
        alert(`Incorporated: ${name} with ${options.length} options`);
    }

    handleAddOption = () => {
        this.setState({ options: this.state.options.concat([{ name: '' }]) });
    }

    handleRemoveOption = (idx) => () => {
        this.setState({ options: this.state.options.filter((s, sidx) => idx !== sidx) });
    }

    state = {
        imgPreview: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
    }
    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ imgPreview: reader.result });
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    componentDidMount(){
        let toggle = document.querySelector('aside');
        let main = document.querySelector('main');
        document.querySelector('.sidebarArrow').onclick = function() {
            this.classList.toggle('rotateMenu');
            toggle.classList.toggle('sidebarOpen');
            main.classList.toggle('mainSec');
            $('.sidebarClose .menuName').toggleClass('d-block');
            $('body').toggleClass('mobileOverlay');
        };


        const changeStatus = () => {
            const select = document.querySelector('.inSelect');
            select.value = 'inprocess';
        };
        document.querySelector('.acceptIssue').addEventListener('click', changeStatus);

        // let btn = $('.issueAction .btn-outline-success');
        $('.issueAction .btn-outline-success').on('click', function(){
            $(this).removeClass('btn-outline-success');
            $(this).addClass('btn-success');
            $(this).html('<i className="fa-solid fa-circle-check"></i> Accepted');
        })
        // btn.onclick = function() {
        //     btn.classList.toggle('btn-outline-success');
        //     btn.classList.toggle('btn-success');
        //     this.innerHTML = '<i className="fa-solid fa-circle-check"></i> Accepted';
        // };

        // const container = document.querySelector("#postModal"),
        // privacy = container.querySelector(".post .privacy"),
        // arrowBack = container.querySelector(".audience .arrow-back"),
        // postSec = container.querySelector(".post"),
        // audienceSec = container.querySelector(".audience");

        $('.post .privacy').on('click', function(){
            $(".audience").addClass('d-block');
            $(".post").addClass('d-none');
        });

        $('.audience .arrow-back').on('click', function(){
            $(".audience").addClass('d-none');
            $(".post").addClass('d-none');
            $(".post").addClass('d-block');
        });

        $('.uploadImgBtn').on('click', function(){
            $(".uploadImg").toggle('d-block');
        });


        // file upload and delete

    }

    handleCloseModal = () => {
        this.setState({ isOpen: false });
    };



    render(){
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 4,
            slidesToScroll: 4
        };
        const {imgPreview} = this.state;



        return(
            <>

                <aside className='sidebarClose oreder-1'>
                    {/* <div className="p-2 bg-light offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample">
                        <div className="sidebar-nav mb-3">
                            <ul className="navbar-nav justify-content-end flex-grow-1">
                                <li className="nav-item">
                                    <Link to="/feed" className="nav-link active"><span className="material-icons me-3">house</span><span>Home</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/profile" className="nav-link"><span className="material-icons me-3">account_circle</span> <span>Profile</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/notification" className="nav-link"><span className="material-icons me-3">notifications</span> <span>Notification</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#languageModal"><span className="material-icons me-3">translate</span> <span>Language</span></Link>
                                </li>

                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/contact"><i className="fa-solid fa-address-card fa-lg me-3"></i> Contact</Link></li>
                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/about"><i className="fa-sharp fa-solid fa-circle-info fa-lg me-3"></i> About Us</Link></li>
                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/edit-profile"><i className="fa-solid fa-user fa-lg me-3"></i> Edit Profile</Link></li>
                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/faq"><i className="fa-sharp fa-solid fa-circle-info fa-lg me-3"></i> FAQ</Link></li>
                                <li className="nav-item">
                                    <Link to="/feed" className="nav-link"><span className="material-icons me-3">logout</span> <span>Logout</span></Link>
                                </li>
                            </ul>
                        </div>
                        <div className='d-flex regiser'>
                            <Link to="#" className="btn btn-secondary w-100 text-decoration-none rounded-2 py-2 fw-bold text-uppercase mr-2" data-bs-toggle="modal" data-bs-target="#signModal">Log in</Link>
                            <Link to="#" className="btn btn-secondary w-100 text-decoration-none rounded-2 py-2 fw-bold text-uppercase m-0" data-bs-toggle="modal" data-bs-target="#signModal">Create an Account</Link>
                        </div>
                    </div> */}

                    <div className="ps-0 fix-sidebar">
                        <div className="sidebar-nav mb-3">
                            <ul className="navbar-nav justify-content-end flex-grow-1">
                                <li className="nav-item">
                                    <Link to="/feed" className="nav-link active"><span className="material-icons me-3">house</span><span className='menuName'>Home</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/profile" className="nav-link"><span className="material-icons me-3">account_circle</span> <span className='menuName'>Profile</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/notification" className="nav-link"><span className="material-icons me-3">notifications</span> <span className='menuName'>Notification</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#languageModal"><span className="material-icons me-3">translate</span> <span className='menuName'>Language: <span className='text-primary small'>English <i className="fa-solid fa-caret-down"></i></span></span></Link>
                                </li>
                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/contact"><i className="fa-solid fa-address-card fa-lg me-3"></i> <span className='menuName'>Contact</span></Link></li>
                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/about"><i className="fa-sharp fa-solid fa-circle-info fa-lg me-3"></i> <span className='menuName'>About Us</span></Link></li>

                                <li><Link className="dropdown-item rounded-3 px-2 py-1 my-1" to="/faq"><i className="fa-sharp fa-solid fa-circle-info fa-lg me-3"></i> <span className='menuName'>FAQ</span></Link></li>
                            </ul>
                            <div className='d-flex regiser'>
                                <Link to="#" className="btn btn-secondary w-100 text-decoration-none rounded-2 py-2 fw-bold text-uppercase mr-2 menuName bg-violet-500" data-bs-toggle="modal" data-bs-target="#signModal">Log in</Link>
                                <Link to="#" className="btn btn-secondary w-100 text-decoration-none rounded-2 py-2 fw-bold text-uppercase m-0 menuName" data-bs-toggle="modal" data-bs-target="#signModal">Create an Account</Link>
                            </div>
                        </div>
                    </div>

                    <button type="button" className='sidebarArrow'>
                        <img src={require("../../assets/img/right-arrow.png")} alt="sidebar" />
                    </button>
                </aside>

                <div className="modal fade" id="taggedModal">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content rounded-4 p-2 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <p className='m-0'>Tagged People</p>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>

                            <div className="modal-body p-0 mb-3">
                                {
                                    allData && allData.map( detail => {
                                        return(
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <div className='scrollList taggedList'>
                                                        <p className='stickyHeader'><strong>Tagged Netas for this Issue ({JSON.parse(JSON.stringify(detail.user[0].post[0].tagged_neta)).length})</strong></p>
                                                        <ul className="list-group list-group-flush account-follow">
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta1</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck1" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck1"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="scrollList commitedList">
                                                        <p className='stickyHeader'><strong>Commited Netas for this Issue</strong></p>
                                                        <ul className="list-group list-group-flush account-follow">
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta1</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck4" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck4"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta2</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck5" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck5"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta3</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck6" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck6"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="scrollList solvedList">
                                                        <p className='stickyHeader'><strong>Netas who already comleted/Solved</strong></p>
                                                        <ul className="list-group list-group-flush account-follow">
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta1</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck7" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck7"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta2</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck8" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck8"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li className="list-group-item p-0">
                                                                <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                                    <div>
                                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                                        <div className="text-muted fw-light">
                                                                            <p className="mb-1 small">@neta3</p>
                                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                            <input type="checkbox" className="btn-check" id="btncheck9" />
                                                                            <label className="btn btn-outline-secondary btn-sm px-2" htmlFor="btncheck9"><span className="follow"><i className="fa-solid fa-plus"></i> Follow</span><span className="following d-none">Following</span></label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="postModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                {/* <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                </div> */}
                                <div className='d-flex align-items-center ms-auto'>
                                    {/* <button type="button" className='btn btn-default me-2' data-bs-target="#selectLocation" data-bs-toggle="modal"><i className="fa-solid fa-location-dot"></i></button> */}
                                    {/* <button type="button" className='btn btn-default' data-bs-target="#tagNetaList"><i className="fa-solid fa-user-tag"></i></button> */}
                                    <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                                </div>
                            </div>
                            <form>
                                <div className="modal-body p-0 mb-3">
                                    <div className="wrapper">
                                        <section className="post">
                                            <div className='px-1 py-1 bg-white shadow-sm rounded-3'>
                                                <div className="form-check form-switch d-flex align-items-center justify-content-between ps-3">
                                                    <label className="form-check-label" htmlFor="postAnonymous">Post anonymously</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="postAnonymous"/>
                                                </div>
                                            </div>
                                            <form action="#">
                                                <div className="content mt-2">
                                                    <img src={require("../../assets/img/icons/Logo.png")} alt="logo" />
                                                    <div className="details">
                                                        <p className='m-0'>Ramesh Kumar</p>
                                                        <div className="privacy">
                                                            <i className="fas fa-user-friends"></i>
                                                            <span>Friends</span>
                                                            <i className="fas fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea placeholder="What's the Issue?" spellcheck="false" required></textarea>
                                                <div className='pollOptions mt-2 text-end'>
                                                    {this.state.options.map((option, idx) => (
                                                        <div className='form-group d-flex justify-content-between mb-2' key={option}>
                                                            <input type="text" className='form-control me-2' placeholder={`Option ${idx + 1}`} onChange={this.handleOptionNameChange(idx)} />
                                                            <button type="button" onClick={this.handleRemoveOption(idx)} className="small btn btn-secondary"><i className="fa-solid fa-minus"></i></button>
                                                        </div>
                                                    ))}
                                                    <button type="button" onClick={this.handleAddOption} className="small btn btn-secondary mr-auto"><i className="fa-solid fa-plus"></i></button>
                                                </div>
                                                <section className='uploadImg d-none'>
                                                    <form className='m-0'>
                                                        <img className='imgPreview' src={imgPreview} alt="" />
                                                        <input type="file" name="img-preview" className='form-control' accept="image/*" onChange={this.imageHandler}/>
                                                    </form>
                                                </section>
                                                <div className="options">
                                                    <p>Add to Your Post</p>
                                                    <ul className="list">
                                                        <li className='uploadImgBtn'><img src={require("../../assets/img/icons/gallery.svg").default} alt="gallery"/></li>
                                                        <li><img src={require("../../assets/img/icons/tag.svg").default} alt="tag"/></li>
                                                        <li><i className="fa-solid fa-location-dot"></i></li>
                                                    </ul>
                                                </div>
                                                <button type="submit" className='btn btn-secondary'>Post</button>
                                            </form>
                                        </section>

                                        <section className="audience">
                                            <header>
                                                <div className="arrow-back"><i className="fas fa-arrow-left"></i></div>
                                                <p>Select Audience</p>
                                            </header>
                                            <div className="content">
                                                <p>Who can see your post?</p>
                                                <span>Your post will show up in News Feed, on your profile and in search results.</span>
                                            </div>
                                            <ul className="list">
                                                <li>
                                                    <div className="column" id="public">
                                                        <div className="icon"><i className="fas fa-globe-asia"></i></div>
                                                        <div className="details">
                                                            <p>Public</p>
                                                            <span>Anyone on Nextneta</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="selectAudience" for="public" className="radio" />
                                                </li>
                                                <li>
                                                    <div className="column" id="friends">
                                                        <div className="icon"><i className="fas fa-user-friends"></i></div>
                                                        <div className="details">
                                                            <p>Friends</p>
                                                            <span>Your friends on Nextneta</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="selectAudience" for="friends" className="radio" />
                                                </li>
                                                <li>
                                                    <div className="column" id="neta">
                                                        <div className="icon"><i className="fas fa-user"></i></div>
                                                        <div className="details">
                                                            <p>Specific Neta</p>
                                                            <span>Only show to some Neta</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="selectAudience" for="neta" className="radio" />
                                                </li>
                                                <li>
                                                    <div className="column" id="onlyMe">
                                                        <div className="icon"><i className="fas fa-lock"></i></div>
                                                        <div className="details">
                                                            <p>Only me</p>
                                                            <span>Only you can see your post</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="selectAudience" for="onlyMe" className="radio" />
                                                </li>
                                                <li>
                                                    <div className="column" id="custom">
                                                        <div className="icon"><i className="fas fa-cog"></i></div>
                                                        <div className="details">
                                                            <p>Custom</p>
                                                            <span>Include and exclude friends</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="selectAudience" for="custom" className="radio" />
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                    {/* <div className="d-flex align-items-center">
                                        <div className="form-floating w-90">
                                            <textarea className="form-control rounded-5 border-0 shadow-sm" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                                            <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">What's the Issue?</label>
                                        </div>
                                        <div className='p-3' data-bs-toggle="modal" data-bs-target="#uploadImg">
                                            <label htmlFor='input-file'><i className="fa-solid fa-image"></i></label>
                                        </div>
                                    </div>
                                    <div className='options mt-2 text-end'>
                                        {this.state.options.map((option, idx) => (
                                            <div className='form-group d-flex justify-content-between mb-2' key={option}>
                                                <input type="text" className='form-control me-2' placeholder={`Option ${idx + 1}`} onChange={this.handleOptionNameChange(idx)} />
                                                <button type="button" onClick={this.handleRemoveOption(idx)} className="small btn btn-secondary"><i className="fa-solid fa-minus"></i></button>
                                            </div>
                                        ))}
                                        <button type="button" onClick={this.handleAddOption} className="small btn btn-secondary mr-auto"><i className="fa-solid fa-plus"></i></button>
                                    </div> */}
                                </div>
                                {/* <div className="modal-footer justify-content-start px-1 py-1 bg-white shadow-sm rounded-5">
                                    <div className="form-check form-switch d-flex align-items-center">
                                        <input className="form-check-input" type="checkbox" role="switch" id="postAnonymous"/>
                                        <label className="form-check-label" htmlFor="postAnonymous">As anonymous</label>
                                    </div>
                                    <div className="ms-auto m-0">
                                        <Link data-bs-dismiss="modal" onClick={this.handleAddOption} to="#" className="btn btn-primary rounded-5 fw-bold px-3 py-2 fs-6 mb-0 d-flex align-items-center"><span className="material-icons me-2 md-16">send</span>Post</Link>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="selectAudience" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-toggle="modal" data-bs-target="#postModal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-2 d-flex">
                                        <span className="material-icons md-32">account_circle</span>
                                        <p className='fw-bold mb-0 ms-1'> Ramesh Singh</p>
                                    </h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <button type="button" className='btn btn-default me-2' data-bs-target="#selectLocation" data-bs-toggle="modal"><i className="fa-solid fa-location-dot"></i></button>
                                    <button type="button" className='btn btn-default' data-bs-target="#tagNetaList" data-bs-toggle="modal"><i className="fa-solid fa-user-tag"></i></button>
                                    <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                                </div>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <img className='imgPreview' src={imgPreview} alt="" />
                                    <input type="file" name="img-preview" className='form-control' accept="image/*" onChange={this.imageHandler}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade" id="uploadImg" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-toggle="modal" data-bs-target="#postModal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-2 d-flex">
                                        <span className="material-icons md-32">account_circle</span>
                                        <p className='fw-bold mb-0 ms-1'> Ramesh Singh</p>
                                    </h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <button type="button" className='btn btn-default me-2' data-bs-target="#selectLocation" data-bs-toggle="modal"><i className="fa-solid fa-location-dot"></i></button>
                                    <button type="button" className='btn btn-default' data-bs-target="#tagNetaList" data-bs-toggle="modal"><i className="fa-solid fa-user-tag"></i></button>
                                    <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                                </div>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <img className='imgPreview' src={imgPreview} alt="" />
                                    <input type="file" name="img-preview" className='form-control' accept="image/*" onChange={this.imageHandler}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="modal fade" id="tagNetaList" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-toggle="modal" data-bs-target="#postModal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0" id="staticBackdropLabel">Tag Neta</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <div className="d-flex align-items-center">
                                    {/* <ReactTags
                                        tags={tags}
                                        suggestions={suggestions}
                                        delimiters={delimiters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        handleDrag={handleDrag}
                                        handleTagClick={handleTagClick}
                                        inputFieldPosition="top"
                                        autocomplete
                                        editable
                                    /> */}
                                    <button className='btn btn-default'>Done</button>
                                </div>
                                <div>
                                    <p className='small fw-bold'>SUGGESTIONS</p>
                                    <div>
                                        <ul className="list-group list-group-flush account-follow">
                                            <li className="list-group-item p-0">
                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                    <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                    <div>
                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                        <div className="text-muted fw-light">
                                                            <p className="mb-1 small">@neta1</p>
                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" className="btn-check" id="btncheck8" />
                                                            <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="list-group-item p-0">
                                                <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                                    <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                    <div>
                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                        <div className="text-muted fw-light">
                                                            <p className="mb-1 small">@neta2</p>
                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" className="btn-check" id="btncheck9" />
                                                            <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="list-group-item p-0">
                                                <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                                    <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                                    <div>
                                                        <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                        <div className="text-muted fw-light">
                                                            <p className="mb-1 small">@neta3</p>
                                                            <span className="text-muted d-flex align-items-center small">Politician</span>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" className="btn-check" id="btncheck10" />
                                                            <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="referenceModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header p-2">
                                <h6 className="modal-title" id="referenceModalLabel"><strong>Reference Information/Material</strong></h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Link to="#" data-bs-target="#addReference" data-bs-toggle="modal" className='btn btn-sm btn-secondary'>Add Reference Information</Link>

                                <div className='referenceList'>
                                    <div className="bg-white p-3 feed-item rounded-2 mb-3 shadow-sm p-2 mt-2">
                                        <h6><strong>Reference Information</strong></h6>
                                        <Slider {...settings} >
                                            <div className="p-2 m-2 w-100 card">
                                                <div>
                                                    <iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex align-items-center'>
                                                        <p className='small m-0 me-2'>Useful?</p>
                                                        <button className='btn btn-outline-secondary btn-sm me-2'><i className="fa-solid fa-thumbs-up"></i></button>
                                                        <button className='btn btn-outline-secondary btn-sm'><i className="fa-solid fa-thumbs-down"></i></button>
                                                    </div>
                                                    <div>
                                                        <div className="dropdown">
                                                            <Link
                                                                to="#"
                                                                className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                                id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                more_vert
                                                            </Link>
                                                            <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    <Link className="dropdown-item text-muted" to="#"><span className="material-icons md-13 me-1">edit</span>Report</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className="dropdown-item text-muted d-flex align-items-center" to="#"><span className="material-icons md-13 me-1">share</span>Share via another apps</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addReference" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <Link to="#" className="text-muted text-decoration-none material-icons me-3" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                <h6 className="modal-title text-muted" id="staticBackdropLabel">Add Reference Information/Material</h6>
                                <Link data-bs-dismiss="modal" to="#" className="btn btn-primary btn-sm rounded-5 fw-bold"><span className="material-icons me-2 md-16">send</span>Submit</Link>
                            </div>
                            <form>
                                <div className="modal-body p-0 mb-3">
                                    <input class="form-control mb-2" type="file" multiple placeholder="Select Files" name="demo1[]" />
                                    <small>If you want to add video reference then paste link.</small>
                                    {this.state.options.map((option, idx) => (
                                    <div className="form-group d-flex mb-2">
                                        <input type="text" className="form-control rounded-5 border-0 shadow-sm" placeholder="Paste Link" id="floatingTextarea2"/>
                                        <button type="button" onClick={this.handleRemoveOption(idx)} className="small btn btn-secondary">-</button>
                                    </div>
                                    ))}
                                    <button type="button" onClick={this.handleAddOption} className="small btn btn-secondary">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="signModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0">
                            <div className="modal-header border-0 p-1">
                                <h6 className="modal-title fw-bold text-body fs-6" id="exampleModalLabel">Choose Language</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form>
                                <div className="modal-body p-0">
                                    <div className="row py-3 gy-3 m-0">
                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="hindi1" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="hindi1">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">हिंदी</small>
                                                    <small className="ln-18">Hindi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="english2" defaultChecked />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="english2">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">English</small>
                                                    <small className="ln-18">English</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="kannada3" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="kannada3">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ಕನ್ನಡ</small>
                                                    <small className="ln-18">kannada</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="tamil4" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="tamil4">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">தமிழ்</small>
                                                    <small className="ln-18">Tamil</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi5" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi5">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ਪੰਜਾਬੀ</small>
                                                    <small className="ln-18">Punjabi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi511f" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi511f">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ગુજરાતી</small>
                                                    <small className="ln-18">Gujarati</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi51f" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi51f">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">मराठी</small>
                                                    <small className="ln-18">Marathi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="other" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="other">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">తెలుగు</small>
                                                    <small className="ln-18">Telugu</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="mt-5 login-register" id="number">
                                        <h6 className="fw-bold mx-1 mb-2 text-dark">Register your Mobile Number</h6>
                                        <div className="row mx-0 mb-3">
                                            <div className="col-3 p-1">
                                                <div className="form-floating">
                                                    <select className="form-select rounded-5" id="floatingSelect" aria-label="Floating label select example">
                                                        <option defaultValue>+91</option>
                                                        <option defaultValue="1">+34</option>
                                                        <option defaultValue="2">+434</option>
                                                        <option defaultValue="3">+343</option>
                                                    </select>
                                                    <label htmlFor="floatingSelect">Code</label>
                                                </div>
                                            </div>
                                            <div className="col-9 p-1">
                                                <div className="form-floating d-flex align-items-end">
                                                    <input type="text" className="form-control rounded-5" id="floatingName" defaultValue="1234567890" placeholder="Enter Mobile Number" />
                                                    <label htmlFor="floatingName">Enter Mobile Number</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-1">
                                            <button type="button" className="btn btn-primary w-100 text-decoration-none rounded-5 py-3 fw-bold text-uppercase m-0" data-bs-dismiss="modal">Send OTP</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="languageModal" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-0 rounded-4 p-4">
                            <div className="modal-header border-0 p-1">
                                <h6 className="modal-title fw-bold text-body fs-6 d-flex justify-content-center" id="exampleModalLabel1">Choose Language</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form>
                                <div className="modal-body pt-0 px-0">
                                    <div className="row py-3 gy-3 m-0">
                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="hindi1" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="hindi1">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">हिंदी</small>
                                                    <small className="ln-18">Hindi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="english2" defaultChecked />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="english2">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">English</small>
                                                    <small className="ln-18">English</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="kannada3" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="kannada3">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ಕನ್ನಡ</small>
                                                    <small className="ln-18">kannada</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="tamil4" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="tamil4">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">தமிழ்</small>
                                                    <small className="ln-18">Tamil</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi5" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi5">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ਪੰਜਾਬੀ</small>
                                                    <small className="ln-18">Punjabi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi511f" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi511f">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">ગુજરાતી</small>
                                                    <small className="ln-18">Gujarati</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="punjabi51f" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi51f">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">मराठी</small>
                                                    <small className="ln-18">Marathi</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>

                                        <div className="langauge-item col-6 col-md-3 px-1 mt-2">
                                            <input type="radio" className="btn-check" name="options-outlined" id="other" />
                                            <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="other">
                                                <span className="text-start d-grid">
                                                    <small className="ln-18">తెలుగు</small>
                                                    <small className="ln-18">Telugu</small>
                                                </span>
                                                <span className="material-icons text-muted md-20">check_circle</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 p-1">
                                    <button type="button" className="btn btn-primary w-100 text-decoration-none rounded-5 py-3 fw-bold text-uppercase m-0" data-bs-dismiss="modal">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="detailInfo" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div className="modal-dialog modal-xl modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header p-2">
                                <h6 className="modal-title" id="referenceModalLabel"><strong>Reference Information/Material</strong></h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='referenceList'>
                                    <div className="bg-white feed-item rounded-2 mb-3 mt-2">
                                        <ul className="top-osahan-nav-tab nav mb-2 nav-pills justify-content-center nav-justified shadow-sm rounded-2 overflow-hidden bg-white" id="pills-tab" role="tablist">
                                            <li className="nav-item w-25" role="presentation">
                                                <button className="p-2 nav-link text-muted active" id="pills-pendingtask-tab1" data-bs-toggle="pill"
                                                    data-bs-target="#byUser" type="button" role="tab"
                                                    aria-selected="true">Reference material by affected users</button>
                                            </li>
                                            <li className="nav-item w-50" role="presentation">
                                                <button className="p-2 nav-link text-muted" id="pills-commitedtask-tab1" data-bs-toggle="pill"
                                                    data-bs-target="#byNeta" type="button" role="tab" aria-controls="pills-commitedtask1"
                                                    aria-selected="false">Reference material by Neta</button>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane border-0 fade show active" id="byUser" role="tabpanel">
                                                <div className="ms-1">
                                                    <div className="feeds">
                                                        <div className='bg-white rounded-2 shadow-sm p-1'>
                                                            <Link to="#" data-bs-target="#addReference" data-bs-toggle="modal" className='btn btn-sm btn-secondary'>Add Reference Information</Link>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="p-2 m-2 w-100 card" tabindex="-1">
                                                                        <div><iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="d-flex align-items-center mt-1">
                                                                                <p className="small m-0 me-2">are you satisfy ?</p>
                                                                                <button className="btn btn-outline-secondary btn-sm me-2"><i className="fa-solid fa-thumbs-up"></i></button>
                                                                                <button className="btn btn-outline-secondary btn-sm"><i className="fa-solid fa-thumbs-down"></i></button>
                                                                            </div>
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <a className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" href="/krupaltesting/nextneta/feed">more_vert</a>
                                                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item text-muted" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">edit</span>Report</a></li>
                                                                                    <li><a className="dropdown-item text-muted d-flex align-items-center" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">share</span>Share via another apps</a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="p-2 m-2 w-100 card" tabindex="-1">
                                                                        <div><iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="d-flex align-items-center mt-1">
                                                                                <p className="small m-0 me-2">are you satisfy ?</p>
                                                                                <button className="btn btn-outline-secondary btn-sm me-2"><i className="fa-solid fa-thumbs-up"></i></button>
                                                                                <button className="btn btn-outline-secondary btn-sm"><i className="fa-solid fa-thumbs-down"></i></button>
                                                                            </div>
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <a className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" href="/krupaltesting/nextneta/feed">more_vert</a>
                                                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item text-muted" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">edit</span>Report</a></li>
                                                                                    <li><a className="dropdown-item text-muted d-flex align-items-center" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">share</span>Share via another apps</a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="p-2 m-2 w-100 card" tabindex="-1">
                                                                        <div><iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="d-flex align-items-center mt-1">
                                                                                <p className="small m-0 me-2">are you satisfy ?</p>
                                                                                <button className="btn btn-outline-secondary btn-sm me-2"><i className="fa-solid fa-thumbs-up"></i></button>
                                                                                <button className="btn btn-outline-secondary btn-sm"><i className="fa-solid fa-thumbs-down"></i></button>
                                                                            </div>
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <a className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" href="/krupaltesting/nextneta/feed">more_vert</a>
                                                                                    <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item text-muted" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">edit</span>Report</a></li>
                                                                                    <li><a className="dropdown-item text-muted d-flex align-items-center" href="/krupaltesting/nextneta/feed"><span className="material-icons md-13 me-1">share</span>Share via another apps</a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane border-0 fade" id="byNeta" role="tabpanel">
                                                <div className="ms-1">
                                                    <div className="feeds">
                                                        <div className='bg-white rounded-2 shadow-sm'>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-12">
                                                                    <div className="accordion overflow-hidden bg-white">
                                                                        <h4>Netas who already comleted/Solved</h4>
                                                                        <div className="accordion-item">
                                                                            <div className="accordion-header d-flex align-content-center">
                                                                                <div className='accordion-button m-0 p-1'>
                                                                                    <button className="fw-bold d-flex w-50 me-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNeta1" aria-expanded="true" aria-controls="collapseNeta1">
                                                                                        <img width={40} src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-1" alt="profile-img" />
                                                                                        <div className='d-flex justify-content-start flex-column'>
                                                                                            <p className='m-0'>Neta 1</p>
                                                                                            <p className='text-muted m-0'><small>(2 days ago)</small></p>
                                                                                        </div>
                                                                                    </button>
                                                                                    <div className="d-flex align-items-center justify-content-center text-success">
                                                                                        <p className="small m-0 me-1">Are you satisfied ?</p>
                                                                                        <button className="btn btn-secondary2 btn-sm me-1" data-bs-toggle="modal" data-bs-target="#profileAffectedGraphUser"><i className="far fa-chart-bar"></i></button>
                                                                                        <div className="btn-group" role="group">
                                                                                            <button className='btn btn-outline-success btn-sm px-2 mr-2'><i className="fa-solid fa-thumbs-up"></i> 91 (75%)</button>
                                                                                            <button className='btn btn-outline-danger btn-sm px-2'><i className="fa-solid fa-thumbs-down"></i> 26 (25%)</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                
                                                                            </div>
                                                                            <div className="accordion-collapse collapse show" id="collapseNeta1">
                                                                                <div className="accordion-body p-2">
                                                                                    
                                                                                    <Link to="#" data-bs-target="#addReference" data-bs-toggle="modal" className='btn btn-sm btn-secondary mb-1'>Add Reference Information</Link>
                                                                                    
                                                                                    <div className="row g-2">
                                                                                        <div className="col-md-4 col-6">
                                                                                            <div className="p-2 w-100 card" tabindex="-1">
                                                                                                <div><iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6">
                                                                                            <div class="refCard">
                                                                                                <h5>Susen road repair report</h5>
                                                                                                <div class="step-details">
                                                                                                    <div class="step-deadline error"><i class="fa fa-clock-o"> </i> 2 days ago</div>
                                                                                                </div>
                                                                                                <aside class="card-actions d-flex flex-column">
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">View task details</div>
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">PDF preview</div>
                                                                                                </aside>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6">
                                                                                            <div class="refCard">
                                                                                                <h5>Waghodia bridge repaire report</h5>
                                                                                                <div class="step-details">
                                                                                                    <div class="step-deadline error"><i class="fa fa-clock-o"> </i> 2 days ago</div>
                                                                                                </div>
                                                                                                <aside class="card-actions d-flex flex-column">
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">View task details</div>
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">PDF preview</div>
                                                                                                </aside>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="accordion-item">
                                                                            <div className="accordion-header d-flex align-content-center">
                                                                                <button className="accordion-button fw-bold m-0 p-1" type="button"
                                                                                    data-bs-toggle="collapse" data-bs-target="#collapseNeta2"
                                                                                    aria-expanded="true" aria-controls="collapseNeta1">
                                                                                        <img width={50} src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-2" alt="profile-img" />
                                                                                    Neta 2
                                                                                    <p className='text-muted m-0 ms-5'>(4 days ago)</p>
                                                                                </button>
                                                                            </div>
                                                                            <div className="accordion-collapse collapse" id="collapseNeta2">
                                                                                <div className="accordion-body p-2">
                                                                                    <div className="d-flex align-items-center justify-content-center text-success mt-1 mb-2">
                                                                                        <p className="small m-0 me-2">are you satisfy ?</p>
                                                                                        <button className="btn btn-outline-success btn-sm me-2"><i className="fa-solid fa-thumbs-up"></i></button>
                                                                                        <button className="btn btn-outline-danger btn-sm"><i className="fa-solid fa-thumbs-down"></i></button>
                                                                                    </div>
                                                                                    <Link to="#" data-bs-target="#addReference" data-bs-toggle="modal" className='btn btn-sm btn-secondary mb-1'>Add Reference Information</Link>
                                                                                    
                                                                                    <div className="row g-2">
                                                                                        <div className="col-md-4 col-6">
                                                                                            <div className="p-2 w-100 card" tabindex="-1">
                                                                                                <div><iframe src="https://www.youtube.com/embed/x7bYuNEZ9eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6">
                                                                                            <div class="refCard">
                                                                                                <h5>Susen road repair report</h5>
                                                                                                <div class="step-details">
                                                                                                    <div class="step-deadline error"><i class="fa fa-clock-o"> </i> 2 days ago</div>
                                                                                                </div>
                                                                                                <aside class="card-actions d-flex flex-column">
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">View task details</div>
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">PDF preview</div>
                                                                                                </aside>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6">
                                                                                            <div class="refCard">
                                                                                                <h5>Waghodia bridge repaire report</h5>
                                                                                                <div class="step-details">
                                                                                                    <div class="step-deadline error"><i class="fa fa-clock-o"> </i> 2 days ago</div>
                                                                                                </div>
                                                                                                <aside class="card-actions d-flex flex-column">
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">View task details</div>
                                                                                                    <div class="btn btn-secondary btn-sm mb-1">PDF preview</div>
                                                                                                </aside>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="issueDate" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center">
                                <a href="#" className="btn btn-primary text-right me-3 acceptIssue" data-bs-dismiss="modal">Confirm</a>
                                <h5 className="modal-title">Commit to Resolve</h5>
                                <button type="button" className="btn btn-white btn-close" data-bs-dismiss="modal" aria-label="Close"><ion-icon name="close-outline"></ion-icon></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-input mb-2">
                                            <label className="fw-bold">Accepted date</label>
                                            <DatePicker
                                                showIcon
                                                selected={ this.state.acceptDate1 }
                                                name="startDate"
                                                dateFormat="dd/MM/yyyy"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-input">
                                            <label className="fw-bold">Promise of resolve by</label>
                                            <DatePicker
                                                showIcon
                                                selected={ this.state.resolveDate1 }
                                                onChange={ this.resolveDate }
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                maxDate={addDays(new Date(), 600)}
                                                isClearable
                                                placeholderText="Select Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check my-3 d-flex align-items-center">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I commit to resolve this issue by this date.
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="declineIssue" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center">
                                <a href="#" className="btn btn-primary text-right me-3 acceptIssue" data-bs-dismiss="modal">Confirm</a>
                                <h5 className="modal-title">Cancel/Withdraw to Resolve</h5>
                                <button type="button" className="btn btn-white btn-close" data-bs-dismiss="modal" aria-label="Close"><ion-icon name="close-outline"></ion-icon></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-input mb-2">
                                            <label className="fw-bold">Accepted date</label>
                                            <DatePicker
                                                showIcon
                                                selected={ this.state.acceptDate1 }
                                                name="startDate"
                                                dateFormat="dd/MM/yyyy"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-input">
                                            <label className="fw-bold">Promise of resolve by</label>
                                            <DatePicker
                                                showIcon
                                                selected={ this.state.resolveDate1 }
                                                onChange={ this.resolveDate }
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                maxDate={addDays(new Date(), 600)}
                                                isClearable
                                                placeholderText="Select Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check my-3 d-flex align-items-center">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I cancel/withdraw to resolve this issue by this date.
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileAffectedUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Affected Users</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileFollower" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Follower</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta1</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta2</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta3</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileFollowing" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Following</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta1</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta2</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta3</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileTaggedUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Tagged Netas</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta1</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta2</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta3</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileCommitedUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Commited Netas</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta1</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta2</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta3</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileSolvedUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Netas who solved</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta1</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta2</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">Neta 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@neta3</p>
                                                    <span className="text-muted d-flex align-items-center small">Politician</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="profileAffectedGraphUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Issue analytics</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ChartSec></ChartSec>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editIssue" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons me-3" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <button type='submit' className='btn btn-primary mt-2'>Save</button>
                                </div>
                                <h5 className="modal-title text-muted ms-3 ln-0">Edit posted issue</h5>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <div className="pt-3 feeds mb-2">
                                    <div className="bg-white p-2 feed-item rounded-2 mb-3 shadow-sm">
                                        <div className="d-flex">
                                            <div className="d-flex align-items-start w-100">
                                                <div className="w-100">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={require('../../assets/img/rmate2.jpg')} className="me-2 img-fluid rounded-circle user-img" alt="profile-img" />
                                                            <Link to="/profile" className="text-decoration-none">
                                                                <h6 className="fw-bold mb-0 text-body">Remesh Kumar</h6>
                                                                <div>
                                                                    <span className="material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span>
                                                                    <small className="text-muted ms-2">rameshrocks</small>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        <div className="d-flex align-items-center small">
                                                            <div className="dropdown">
                                                                <Link
                                                                    to="#"
                                                                    className="text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-light p-1"
                                                                    id="dropdownMenuButton1"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    more_vert
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="my-2 card p-2">
                                                        <form>
                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                <input type="text" className='form-control w-50' placeholder='Enter issue' value='Online Eduction or Offline Education?' />
                                                                <div className='d-flex align-items-center'>
                                                                    <Link to="#" className="text-muted mb-0 small me-1">20 Apr, 2022</Link>
                                                                    <p className='btn btn-secondary btn-dm btn-rounded py-0 m-0'><i className="fa-solid fa-location-dot"></i> Waghodia, Vadodara</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center mt-1 mb-3'>
                                                                <button className='taggedNeta btn btn-outline-secondary btn-sm btn-rounded followers d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#taggedModal">
                                                                    <p className="m-0 text-decoration-none border-0 material-icons">add_circle</p>
                                                                    <p className='m-0'>Tagged Neta</p>
                                                                    <div className="d-flex me-2">
                                                                        <img src={require('../../assets/img/rmate1.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate2.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                        <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle" alt="follower-img"/>
                                                                    </div>
                                                                    <p className='m-0'>+150</p>
                                                                </button>
                                                                <button className='btn btn-outline-secondary btn-sm taggedNeta btn-rounded d-flex align-items-center'  data-bs-toggle="modal" data-bs-target="#referenceModal"><p className="m-0 text-decoration-none border-0 material-icons">add_circle</p> Reference Information/Material</button>
                                                            </div>
                                                            <div className='card card2 p-2 mb-2'>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="btn-group d-block pollOptionLabel" role="group">
                                                                            <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#allOption">All - 100 Users
                                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                                    <button className='btn btn-secondary2 btn-sm me-2'>100%</button>
                                                                                    <button className='btn btn-secondary2 btn-sm' data-bs-toggle="collapse" data-bs-target="#chartCollapse"><i className="far fa-chart-bar"></i></button>
                                                                                </div>
                                                                            </label>

                                                                            <div className="collapse" id="allOption">
                                                                                <hr className='hr-bold' />
                                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"/>
                                                                                <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio1">30% - 300 Users
                                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm me-2'>30%</button>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm'><i className="far fa-chart-bar"></i></button>
                                                                                    </div>
                                                                                </label>

                                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"/>
                                                                                <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio2">20% - 200 Users
                                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm me-2'>20%</button>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm'><i className="far fa-chart-bar"></i></button>
                                                                                    </div>
                                                                                </label>

                                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"/>
                                                                                <label className="mb-2 btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio3">40% - 400 Users
                                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm me-2'>40%</button>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm'><i className="far fa-chart-bar"></i></button>
                                                                                    </div>
                                                                                </label>

                                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off"/>
                                                                                <label className="btn btn-outline-secondary d-flex align-items-center justify-content-between" htmlFor="btnradio4">10% - 100 Users
                                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm me-2'>10%</button>
                                                                                        <button className='btn btn-outline-secondary text-dark btn-sm'><i className="far fa-chart-bar"></i></button>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <div className="row">
                                                            <div className="col-md-12 collapse" id="chartCollapse">
                                                                <ChartSec></ChartSec>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="updateProfile" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <button type='submit' className='btn btn-primary btn-small'>Save</button>
                                </div>
                                <h5 className="modal-title text-muted ms-3 ln-0">Edit Profile info.</h5>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Name</label>
                                                <input type="text" className="form-control" placeholder='Enter Name'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Date of Birth</label>
                                                <input type="date" className="form-control" placeholder='Enter DOB'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Phone</label>
                                                <input type="text" className="form-control" placeholder='Enter Phone'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Country</label>
                                                <input type="text" list='country' className="form-control" placeholder='Enter Country'/>
                                                <datalist id="country">
                                                    <option value="India" />
                                                    <option value="United State" />
                                                    <option value="Canada" />
                                                    <option value="United Kingdom" />
                                                    <option value="China" />
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">State</label>
                                                <input type="text" list='state' className="form-control" placeholder='Enter State'/>
                                                <datalist id="state">
                                                    <option value="Goa" />
                                                    <option value="Gujarat" />
                                                    <option value="Maharastra" />
                                                    <option value="Bihar" />
                                                    <option value="Uttarpradesh" />
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">City</label>
                                                <input type="text" list='city' className="form-control" placeholder='Enter City'/>
                                                <datalist id="city">
                                                    <option value="Anand" />
                                                    <option value="Vadodara" />
                                                    <option value="Surat" />
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Passcode</label>
                                                <input type="text" className="form-control" placeholder='Enter Passcode'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Username</label>
                                                <input type="text" className="form-control" placeholder='Enter Username'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="form-label">Gender</label>
                                                <select className='form-control'>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="updateNetaProfile" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons me-3" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <button type='submit' className='btn btn-primary btn-sm'>Save</button>
                                </div>
                                <h5 className="modal-title text-muted ms-3 ln-0">Edit Neta Profile info.</h5>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-2">
                                                <label className="form-label">Bio</label>
                                                <input type="text" className="form-control" placeholder='Enter Bio'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-2">
                                                <label className="form-label">Username</label>
                                                <input type="text" className="form-control" placeholder='Enter Username'/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="shareUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-4 p-4 border-0 bg-light">
                            <div className="modal-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                                <div className="d-flex align-items-center justify-content-start">
                                    <Link to="#" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">arrow_back_ios_new</Link>
                                    <h5 className="modal-title text-muted ms-3 ln-0">Share</h5>
                                </div>
                                <Link to="#" className="text-muted text-decoration-none material-icons closeBtn" data-bs-dismiss="modal">close</Link>
                            </div>
                            <div className="modal-body p-0 mb-3">
                                <ul className="list-group list-group-flush account-follow">
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate5.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 1 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck8" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck8"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 border-bottom d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate4.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 2 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck9" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck9"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-group-item p-0">
                                        <Link to="/profile" className="p-2 d-flex text-dark text-decoration-none account-item align-items-center">
                                            <img src={require('../../assets/img/rmate3.jpg')} className="img-fluid rounded-circle me-3" alt="profile-img" />
                                            <div>
                                                <p className="fw-bold mb-0 pe-3 d-flex align-items-center">User 3 <span className="ms-2 material-icons bg-secondary md-16 fw-bold text-white rounded-circle ov-icon">done</span></p>
                                                <div className="text-muted fw-light">
                                                    <p className="mb-1 small">@User</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                    <input type="checkbox" className="btn-check" id="btncheck10" />
                                                    <label className="btn btn-outline-secondary btn-sm px-3 rounded-pill" htmlFor="btncheck10"><span className="follow">+ Follow</span><span className="following d-none">Following</span></label>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Leftside;